<template>
  <div class="admin-command-213LU">
        <b-input-group size="sm" class="mb-3">
            <label class="green-label mt-0">Command Type</label>
            <!--            <b-button @click="$bvModal.show('modal-commands-help')">Commands</b-button>-->
            <b-form-select id="command_type" :options="commandTypes" v-model="commandType"
                           @change="selectCommand"></b-form-select>
        </b-input-group>

<!--          SMS Command-->
          <b-input-group v-if="commandType==='sms'" size="sm" class="justify-content-around">
            <b-input-group-prepend>
              <b-button @click="$bvModal.show('modal-sms-commands-help')">Templates</b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="smsCommandText"
              type="text"
              id="filterInput"
              placeholder="Enter Command to Send"
              debounce="500"
              class="text-input"
              @keydown.native="keydownHandler"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="sendCommand('sms', smsCommandText)" variant="danger">Send via SMS</b-button>
            </b-input-group-append>
          </b-input-group>
          <!--          Custom Command-->
          <b-input-group v-else-if="commandType==='custom'" size="sm">
            <p> Enter the Command text to be sent, including the function code and keyword.
              Do no include the header or tail of the message.
              e.g. 'DE1A2B3C4D04D222B1T' will be sent as '*HQ2011DE1A2B3C4D04D222B1T#'
            </p>
            <b-form-input
              v-model="commandText"
              type="text"
              id="rawJSON"
              placeholder="Enter Command Text e.g. DGwww.abcde.com,8881,T"
              class="text-input"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="sendJSONCommand()" variant="warning">Send</b-button>
            </b-input-group-append>
          </b-input-group>
          <!--          Template Based Command-->
          <div v-if="selectedCommandTemplate" size="sm" class="code-item">
            <p> {{selectedCommandTemplate.description}}
            </p>

            <div v-for="(control, idx) of selectedCommandTemplate.controls" v-bind:key="idx">
              <div class="green-label" v-if="control.title">{{control.title}}</div>
              <b-form-input v-if="control.type === 'input'"
                v-model="selectedCommandData[control.id]"
                type="text"
                :id="control.id+'input'"
                :placeholder="control.placeholder"
                class="text-input"
              ></b-form-input>
              <b-form-select v-if="control.type === 'select'"
                            v-model="selectedCommandData[control.id]"
                            type="text"
                            :id="control.id+'select'"
                            :placeholder="control.placeholder"
                             :options="control.options"
              >
              </b-form-select>
              <b-form-checkbox v-if="control.type === 'check'"
                            v-model="selectedCommandData[control.id]"
                            type="text"
                            :id="control.id+'input'"
                            :placeholder="control.placeholder"
              >{{control.placeholder}}</b-form-checkbox>
            </div>
              <b-button @click="sendTemplateCommand()" variant="warning">Send</b-button>
          </div>




<!--          <b-input-group-append>-->
<!--            <b-button @click="sendCommand('data')" variant="primary">Send via Data</b-button>-->

<!--            <b-button @click="$bvModal.show('modal-json-command')" variant="warning">Send JSON</b-button>-->
<!--          </b-input-group-append>-->
    <b-modal
      id="modal-sms-commands-help"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Command Help"
    >
      <device-command-help modal-id="modal-sms-commands-help" :command-list="smsCommandList"
                           @change="selectSMSCommand"></device-command-help>
    </b-modal>
  </div>
  <!--   Command Help Modal Template-->
</template>

<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import DeviceCommandHelp from '@/components/admin/device_command_types/DeviceCommandHelp'
import * as smsCommands from '@/components/admin/device_command_types/kingwo_sms_commands.json'
import * as dataCommands from '@/components/admin/device_command_types/kingwo_data_commands.json'
import moment from 'moment'
import * as fillTemplate from 'es6-dynamic-template'

export default {
  name: 'admin-command-213LU',
  components: {
    DeviceCommandHelp
  },
  props: {
    device: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      currentCommand: '',
      filter: '',
      deviceInternal: {
        device_id: 1,
        imei: '351608083567144',
        device_type: null
      },
      commandText: '',
      commandType: 'sms',
      commandTemplates: dataCommands.commandList,
      selectedCommandData: {},
      smsCommandText: '*' + this.device.device_imei.slice(-6),
      smsCommandList: smsCommands.commandList,
      dataProcessing: {
        // Turn a given string into a hex string of length l
        hex (x, l = 0) { return parseInt(x).toString(16).padStart(l, '0') },
        // Turn a comma separated list of values into a hex string, with each entry being l length
        hexcsv (x, l = 0) {
          return x.split(',').map(entry =>
            parseInt(entry).toString(16).padStart(l, '0')
          ).join('')
        },
        hexIP (x, l = 0) {
          return x.split('.').map(entry =>
            parseInt(entry).toString(16).padStart(4, '0')
          ).join('')
        },
        // Timezone Parsing (special)
        tz (x, l) {
          let negBit = x > 0 ? '0' : '8' // Negative flag
          return negBit + Math.abs(parseInt(x)).toString(16).padStart(3, '0')
        },
        int (x, l = 0) { return parseInt(x).toString(10).padStart(l) },
        bool (x, l = 0) { return x ? '1' : '0' },
        str (x, l = 0) { return x.toString().trim() }
      }
    }
  },
  async created () {
    if (this.device) {
      this.deviceInternal = this.device
    }
  },
  methods: {
    /***
     * Reset the 'selectedCommandData' when Command type selection is changed and set any defaults that are needed.
     * Triggered by the @change event on the commandTypes select component
     */
    selectCommand: function () {
      if (this.selectedCommandTemplate) {
        this.selectedCommandData = {}
        this.selectedCommandTemplate.controls.forEach(x => {
          if (x.hasOwnProperty('default')) {
            console.log('default ', x.default)
            this.selectedCommandData[x.id] = x.default
          }
        })
        console.log(this.selectedCommandData)
      }
    },
    sendCommand: async function (sendingMethod, data, commandText) {
      console.log('SEND: ', sendingMethod, data, commandText)
      let commandSend = await DataProvider.sendDeviceCommands(this.device.device_imei, data, sendingMethod, commandText)
      if (commandSend.success) {
        console.log('Command Response: ', commandSend)
        this.$bvToast.toast('Command sent to device!',
          {
            title: 'Command Registered',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        this.$emit('change')
      } else {
        ErrorHelper.displayDataErrorToast(commandSend)
      }
    },
    sendJSONCommand: async function () {
      console.log(this.commandText)
      await this.sendCommand('data', this.commandText, 'Custom Command')
    },
    sendTemplateCommand: async function () {
      let data = {}
      for (let control of this.selectedCommandTemplate.controls) {
        if (control.hasOwnProperty('processing') && control.processing) {
          let length = 0
          if (control.hasOwnProperty('length')) {
            // data[control.id] = data[control.id].padStart(control.length, '0')
            length = control.length
          }
          data[control.id] = this.dataProcessing[control.processing](this.selectedCommandData[control.id], length)
          data[control.id + 'Raw'] = this.selectedCommandData[control.id]
        }
      }
      let command = fillTemplate(this.selectedCommandTemplate.stringTemplate, data)

      let readableText = 'Custom Data Command'
      if (this.selectedCommandTemplate.hasOwnProperty('deviceCommandTextTemplate')) {
        readableText = fillTemplate(this.selectedCommandTemplate.deviceCommandTextTemplate, data)
      }
      console.log(readableText)
      this.sendCommand('data', command, readableText)
    },
    // Monitor keys looking for ENTER to be pressed.
    keydownHandler: function (event) {
      if (event.which === 13) {
        this.sendCommand('data')
      }
    },
    selectSMSCommand: function (smsCommand) {
      this.smsCommandText += smsCommand.command
      this.$bvModal.hide('modal-sms-commands-help')
    }
  },
  computed: {
    commandTypes () {
      let commandTypes = [
        {
          text: 'SMS Command',
          value: 'sms'
        },
        {
          text: 'Custom Command',
          value: 'custom'
        }
      ]
      return commandTypes.concat(this.commandTemplates.map(x => {
        return {
          text: x.text,
          value: x.id
        }
      }))
    },
    selectedCommandTemplate () {
      console.log(this.commandTemplates.find(x => x.id === this.commandType))
      return this.commandTemplates.find(x => x.id === this.commandType)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.admin-command-213LU {
  width: 100%;
  color: $text-color-invert;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: $theme-color-background-2;
  border: 1px solid $theme-color-primary-2;
  border-radius: 5px;
}

.command-history {
  background: $default-content-background;
  height: calc(100% - 56px);
  overflow-y: scroll;
  max-height: 60vh;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.tlv-table {
  margin: 1% 1%;
  background: $theme-color-background-1;
}

.w-73 {
  max-width: 73%;
}

.tlv-table-inner {
  width: 100%;
  max-height: 25vh;
  overflow: auto;
}

.tlv-table-inner-small {
  max-height: 15vh;
  overflow: auto;
}

.code-container {
  display: flex;
  flex-direction: column;
}

.code-item {
  display: flex;
  flex-direction: column;
  background: $theme-color-background-1;
  border-radius: 5px;
  margin: 5px 1px;
  padding: 5px;
  border: 1px solid $theme-color-primary-2;
}

.code-item-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
