<template>
  <div class="admin-device-command-queue">
    <div class="health-items">
      <div>
        <div class="green-label">Messages in Queue</div>
        <input v-model="queueMessages" disabled class="text-input">
      </div>
      <div>
        <div class="green-label">Messages Not Visible</div>
        <input v-model="queueNotVisible" disabled class="text-input">
      </div>
      <div>
        <div class="green-label">Queue Last Updated</div>
        <input v-model="lastModifiedTimestamp" disabled class="text-input">
      </div>
    </div>
    <div class="green-divider"></div>
    <div class="command-messages">
      <div v-for="(message, idx) of messages" v-bind:key="idx" class="command-message">
        <div>#{{idx}} Message Id: {{message.id}}</div>
        <b-textarea v-model="message.command_data"></b-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import moment from 'moment'

export default {
  name: 'admin-device-command-queue',
  components: { },
  props: {
    device_imei: String
  },
  data: function () {
    return {
      loading: true,
      queueMessages: null,
      queueNotVisible: null,
      lastModifiedTimestamp: 0,
      messages: []
    }
  },
  async created () {
  },
  async mounted () {
    if (this.device_imei) {
      await this.updateCommandQueue()
    }
  },
  methods: {
    async updateCommandQueue () {
      this.loading = true
      let commandQueueResponse = await DataProvider.getDeviceCommandQueue(this.device_imei)
      if (commandQueueResponse.success) {
        console.log('Command Queue: ', commandQueueResponse)
        // this.deviceHistory = commandQueueResponse.data.sort((a, b) => b.timestamp - a.timestamp)
        // Move scrollbar to bottom
        // this.queueMessages = commandQueueResponse.data.queue_health.ApproximateNumberOfMessages
        // this.queueNotVisible = commandQueueResponse.data.queue_health.ApproximateNumberOfMessagesNotVisible
        // this.lastModifiedTimestamp = moment.unix(commandQueueResponse.data.queue_health.LastModifiedTimestamp).local().format('DD-MM-YYYY HH:mm')
        this.messages = commandQueueResponse.data.queue_content
      } else {
        ErrorHelper.displayDataErrorToast(commandQueueResponse)
      }
      this.loading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../variables";

.admin-command-device {
  width: 100%;
  height: 100%;
  color: $text-color-invert;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.health-items {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
}
.command-message {
  background: $theme-color-background-5;
  border-radius: 25px;
  padding: 10px;
  margin: 5px 10px;
}
</style>
