var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-device-command-queue" }, [
    _c("div", { staticClass: "health-items" }, [
      _c("div", [
        _c("div", { staticClass: "green-label" }, [
          _vm._v("Messages in Queue")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.queueMessages,
              expression: "queueMessages"
            }
          ],
          staticClass: "text-input",
          attrs: { disabled: "" },
          domProps: { value: _vm.queueMessages },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.queueMessages = $event.target.value
            }
          }
        })
      ]),
      _c("div", [
        _c("div", { staticClass: "green-label" }, [
          _vm._v("Messages Not Visible")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.queueNotVisible,
              expression: "queueNotVisible"
            }
          ],
          staticClass: "text-input",
          attrs: { disabled: "" },
          domProps: { value: _vm.queueNotVisible },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.queueNotVisible = $event.target.value
            }
          }
        })
      ]),
      _c("div", [
        _c("div", { staticClass: "green-label" }, [
          _vm._v("Queue Last Updated")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.lastModifiedTimestamp,
              expression: "lastModifiedTimestamp"
            }
          ],
          staticClass: "text-input",
          attrs: { disabled: "" },
          domProps: { value: _vm.lastModifiedTimestamp },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.lastModifiedTimestamp = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "green-divider" }),
    _c(
      "div",
      { staticClass: "command-messages" },
      _vm._l(_vm.messages, function(message, idx) {
        return _c(
          "div",
          { key: idx, staticClass: "command-message" },
          [
            _c("div", [
              _vm._v("#" + _vm._s(idx) + " Message Id: " + _vm._s(message.id))
            ]),
            _c("b-textarea", {
              model: {
                value: message.command_data,
                callback: function($$v) {
                  _vm.$set(message, "command_data", $$v)
                },
                expression: "message.command_data"
              }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }