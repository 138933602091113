var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-edit-device" },
    [
      _c(
        "b-tabs",
        { attrs: { lazy: "" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "General" } },
            [
              _c(
                "label",
                { staticClass: "device-label", attrs: { for: "device_type" } },
                [_vm._v("Device Type")]
              ),
              _c("b-form-select", {
                attrs: { options: _vm.devicetypes },
                on: { change: _vm.changeDeviceType },
                model: {
                  value: _vm.deviceInternal.device_type,
                  callback: function($$v) {
                    _vm.$set(_vm.deviceInternal, "device_type", $$v)
                  },
                  expression: "deviceInternal.device_type"
                }
              }),
              _c("div", { staticClass: "green-divider" }),
              _vm.editComponent
                ? _c(_vm.editComponent, {
                    ref: "editComponent",
                    tag: "component",
                    attrs: {
                      device: _vm.device,
                      deviceType: _vm.deviceInternal.device_type
                    },
                    on: { createDevice: _vm.onCreateDevice }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("b-tab", { attrs: { title: "SIM" } }, [
            !_vm.deviceInternal.sim
              ? _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$bvModal.show("modal-assign-sim-card")
                          }
                        }
                      },
                      [_vm._v("Assign SIM")]
                    ),
                    _vm._v(" No SIM Assigned ")
                  ],
                  1
                )
              : _vm._e(),
            _vm.deviceInternal.sim
              ? _c(
                  "div",
                  [
                    _c("b-button", { on: { click: _vm.unassignSIM } }, [
                      _vm._v("Unassign SIM")
                    ]),
                    _c("admin-edit-sim-card", {
                      attrs: {
                        modal: "false",
                        "sim-card": _vm.deviceInternal.sim
                      },
                      on: {
                        save: function($event) {
                          return _vm.$emit("save")
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("b-tab", { attrs: { title: "Permissions" } }, [
            _c("div", { staticClass: "ml-3" }, [
              _c(
                "label",
                { staticClass: "device-label", attrs: { for: "owner" } },
                [_vm._v("Owner")]
              ),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "property-item" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ownerEmail,
                        expression: "ownerEmail"
                      }
                    ],
                    staticClass: "text-field",
                    attrs: {
                      type: "text",
                      disabled: "",
                      maxlength: "256",
                      "data-name": "owner",
                      placeholder: "Owner ID",
                      id: "owner"
                    },
                    domProps: { value: _vm.ownerEmail },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.ownerEmail = $event.target.value
                      }
                    }
                  })
                ]),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "icon-button",
                  class: _vm.$config.icons.general.assign,
                  attrs: { title: "Assign Device" },
                  on: { click: _vm.showAssignDevice }
                }),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "icon-button-warning",
                  class: _vm.$config.icons.general.unassign,
                  attrs: { title: "Unassign Device" },
                  on: { click: _vm.clickUnAssignDevice }
                })
              ]),
              _c("div", { staticClass: "green-divider" }),
              _c("div", { staticClass: "row justify-content-between" }, [
                _c("label", { staticClass: "device-label" }, [
                  _vm._v("Delegates")
                ])
              ])
            ])
          ]),
          !_vm.isNewDevice
            ? _c(
                "b-tab",
                { attrs: { title: "Healthcheck" } },
                [
                  _c("admin-healthcheck-device", {
                    attrs: { device: _vm.deviceInternal }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.isNewDevice
            ? _c(
                "b-tab",
                { attrs: { title: "Device Trips" } },
                [
                  _c("admin-show-recent-trips", {
                    staticClass: "command-container",
                    attrs: { device: _vm.deviceInternal }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.isNewDevice && this.stateComponent
            ? _c(
                "b-tab",
                { attrs: { title: "Device State" } },
                [
                  this.stateComponent
                    ? _c(this.stateComponent, {
                        ref: "stateComponent",
                        tag: "component",
                        attrs: { device: _vm.device }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.isNewDevice
            ? _c("b-tab", { attrs: { title: "Command History" } }, [
                _c(
                  "div",
                  { staticClass: "command-container" },
                  [
                    _c("admin-command-device", {
                      attrs: { device: _vm.deviceInternal }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "b-tab",
            { attrs: { title: "Auditlog" } },
            [
              !_vm.isNewDevice
                ? _c("AuditLog", {
                    attrs: {
                      related_type: "device",
                      related_id: _vm.deviceInternal.device_id
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer mt-2" },
        [
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.clickClose()
                }
              }
            },
            [_vm._v("Close")]
          ),
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.saveChanges()
                }
              }
            },
            [_vm._v("Save Changes")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-add-delegates",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Share Device With"
          }
        },
        [
          _c("admin-user-list", {
            attrs: {
              "is-modal": "",
              modal: "delegates",
              "multi-select-mode": ""
            },
            on: { change: _vm.onDelegateChange }
          }),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-add-delegates")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.addDelegates()
                    }
                  }
                },
                [_vm._v("Add Delegates")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-assign-owner",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Assign Owner"
          }
        },
        [
          _c("admin-user-list", {
            attrs: {
              "is-modal": "",
              modal: "modal-assign-owner",
              "single-select-mode": ""
            },
            on: { change: _vm.onOwnerChange }
          }),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-assign-owner")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.assignOwner()
                    }
                  }
                },
                [_vm._v("Assign Owner")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-assign-sim-card",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Assign Owner"
          }
        },
        [
          _c("admin-sim-card-list", {
            attrs: { selectable: "", "select-mode": "single" },
            on: { "row-selected": _vm.selectSIMRow }
          }),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-assign-sim-card")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  attrs: { disabled: !_vm.selectedSIM },
                  on: {
                    click: function($event) {
                      return _vm.assignSIM()
                    }
                  }
                },
                [_vm._v("Assign SIM")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }