var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-healthcheck-device" },
    [
      _c(
        "b-card",
        [
          _c(
            "b-card-body",
            {
              attrs: {
                title: "Device Healthcheck - " + _vm.deviceInternal.device_name
              }
            },
            [
              _vm.healthStatus
                ? _c(
                    "b-table-simple",
                    { staticClass: "no-border" },
                    [
                      _c(
                        "b-tbody",
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [_vm._v("IMEI")]),
                              _c("b-td", [
                                _vm._v(_vm._s(_vm.deviceInternal.device_imei))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [_vm._v("Phone #")]),
                              _c("b-td", [
                                _vm._v(_vm._s(_vm.deviceInternal.device_phone))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [_vm._v("Voltage")]),
                              _c("b-td", [
                                _vm._v(_vm._s(_vm.healthStatus.data) + "V")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [_vm._v("Last Heartbeat")]),
                              _c("b-td", [
                                _vm._v(_vm._s(_vm.last_seen_heartbeat))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [_vm._v("Last Location:")]),
                              _c("b-td", [
                                _vm._v(_vm._s(_vm.last_seen_location))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [_vm._v("Subscription?")]),
                              _vm.healthStatus.subscription
                                ? _c("b-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.healthStatus.subscription.active
                                      )
                                    )
                                  ])
                                : _c("b-td", [_vm._v("Not found")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }