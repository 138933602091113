<template>
  <div class="admin-command-gv20">
    <b-input-group size="sm">
      <b-input-group-prepend>
        <b-button @click="$bvModal.show('modal-commands-help')">Commands</b-button>
      </b-input-group-prepend>
      <b-form-input
        v-model="currentCommand"
        type="text"
        id="filterInput"
        placeholder="Enter Command to Send"
        debounce="500"
        class="text-input"
        @keydown.native="keydownHandler"
      ></b-form-input>
      <b-input-group-append>
        <b-button @click="sendCommand('data')" variant="primary">Send via Data</b-button>
        <b-button @click="sendCommand('sms')" variant="danger">Send via SMS</b-button>
      </b-input-group-append>
    </b-input-group>
    <!--   Command Help Modal Template-->
    <b-modal
      id="modal-commands-help"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Command Help"
    >
      <device-command-help modal-id="modal-commands-help" :command-list="commandList" @change="selectCommand"></device-command-help>
    </b-modal>
  </div>
</template>

<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import DeviceCommandHelp from '@/components/admin/device_command_types/DeviceCommandHelp'
import * as commandList from '@/components/admin/device_command_types/gv20_commands.json'

export default {
  name: 'admin-command-gv20',
  components: { DeviceCommandHelp },
  props: {
    device: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      currentCommand: '',
      filter: '',
      deviceHistory: [
      ],
      deviceInternal: {
        device_id: 1,
        device_password: 'password',
        device_phone: '042331344',
        alarm_status: false,
        alarm_changed_time_utc: 32142141414,
        overspeed_limit: 0,
        vibration_alarm_active: false,
        odometer_reading: 0,
        has_subscription: true,
        icon: 'Car',
        imei: '351608083567144',
        name: '<< Loading device >>',
        plant_num: 'matty D',
        registration: '',
        subscription_expiry: '2017-06-01',
        user_id: 1,
        livetrack: false,
        geolimit: false,
        voltage: 12.0,
        hours_tracked: '200'
      },
      // JSON Data for Command Help/Select Modal
      commandFields: [
        { key: 'button',
          label: '-',
          sortable: false,
          thStyle: {
            width: '80px'
          }

        },
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'command',
          label: 'Command',
          sortable: true
        },
        { key: 'description',
          label: 'Description',
          sortable: true
        }
      ],
      refreshTimer: null,
      commandList: commandList.commandList
    }
  },
  async created () {
    console.log(this.commandList)
    if (this.device) {
      this.deviceInternal = this.device
    }
  },
  methods: {
    sendCommand: async function (sendingMethod) {
      let commandSend = await DataProvider.sendDeviceCommands(this.device.device_imei, this.currentCommand, sendingMethod)
      if (commandSend.success) {
        console.log('Command Response: ', commandSend)
        this.$bvToast.toast('Command sent to device!',
          {
            title: 'Command Registered',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        this.$emit('change')
      } else {
        ErrorHelper.displayDataErrorToast(commandSend)
      }
    },
    selectCommand: function (command) {
      console.log(command)
      this.currentCommand = command.command
      this.$bvModal.hide('modal-commands-help')
    },
    // Monitor keys looking for ENTER to be pressed.
    keydownHandler: function (event) {
      if (event.which === 13) {
        this.sendCommand('data')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.admin-command-gv20 {
  width: 100%;
  color: $text-color-invert;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.command-history {
  background: $default-content-background;
  height: calc(100% - 56px);
  overflow-y: scroll;
  max-height: 60vh;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.command-list-container {
  height: 75vh;
  overflow-y: scroll;
}

.command-item {
  text-align: left;
}

.command-item-response {
  text-align: left;
  color: $theme-color-primary-3;
  margin-left: 1rem;
}

.from_device_indicator {
  color: orange;
}
.to_device_indicator {
  color: rgb(102, 199, 72);
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
}
</style>
