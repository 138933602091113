var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-command-device" },
    [
      _c(
        "b-card",
        {},
        [
          _c(
            "b-card-body",
            { staticClass: "flex-column flex-nowrap align-content-stretch" },
            [
              _c("div", { staticClass: "flex-column" }, [
                _vm._v(
                  "Device Command Console - " +
                    _vm._s(_vm.deviceInternal.device_name)
                )
              ]),
              _vm.deviceControlComponent
                ? _c(_vm.deviceControlComponent, {
                    tag: "component",
                    attrs: { device: _vm.deviceInternal },
                    on: { change: _vm.updateCommandHistory }
                  })
                : _vm._e(),
              _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "command-history-title flex-row" }, [
                  _c("div", [_vm._v(" Command History ")]),
                  _c(
                    "div",
                    [
                      _c("b-spinner", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading"
                          }
                        ],
                        attrs: { text: "Loading" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex-row" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip",
                            value: "Show Command Queue",
                            expression: "'Show Command Queue'"
                          }
                        ],
                        staticClass: "icon-button",
                        on: {
                          click: function($event) {
                            return _vm.$bvModal.show("modal-command-queue")
                          }
                        }
                      },
                      [_vm._v(" Q ")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip",
                            value: "Toggle Auto-Scroll",
                            expression: "'Toggle Auto-Scroll'"
                          }
                        ],
                        staticClass: "icon-button",
                        class: { active: _vm.autoScroll },
                        on: {
                          click: function($event) {
                            _vm.autoScroll = !_vm.autoScroll
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-angle-double-up" })]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip",
                            value: "Toggle Auto-Refresh",
                            expression: "'Toggle Auto-Refresh'"
                          }
                        ],
                        staticClass: "icon-button",
                        class: { active: _vm.refreshTimer },
                        on: {
                          click: function($event) {
                            return _vm.toggleTimer()
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-history" })]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip",
                            value: "Refresh",
                            expression: "'Refresh'"
                          }
                        ],
                        staticClass: "icon-button",
                        on: {
                          click: function($event) {
                            return _vm.updateCommandHistory()
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-refresh" })]
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    ref: "commandHistory",
                    staticClass: "command-history",
                    attrs: { id: "command-history" }
                  },
                  [
                    _vm._l(_vm.deviceHistory, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          class: {
                            selected:
                              _vm.selectedCommand !== null &&
                              item.id === _vm.selectedCommand.id
                          }
                        },
                        [
                          _c("div", { staticClass: "command-item" }, [
                            item.direction === "to_device"
                              ? _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip"
                                    }
                                  ],
                                  staticClass: "ml-1",
                                  class: _vm.$config.icons.messaging.to_device,
                                  attrs: { title: "TO Device" }
                                })
                              : _vm._e(),
                            item.direction === "from_device"
                              ? _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip"
                                    }
                                  ],
                                  staticClass: "ml-1",
                                  class:
                                    _vm.$config.icons.messaging.from_device,
                                  attrs: { title: "FROM Device" }
                                })
                              : _vm._e(),
                            item.method === "data"
                              ? _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip"
                                    }
                                  ],
                                  staticClass: "ml-1",
                                  class: _vm.$config.icons.messaging.data,
                                  attrs: { title: "via DATA" }
                                })
                              : _vm._e(),
                            item.method === "sms" || item.method === "Twilio"
                              ? _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip"
                                    }
                                  ],
                                  staticClass: "ml-1",
                                  class: _vm.$config.icons.messaging.sms,
                                  attrs: { title: "via SMS" }
                                })
                              : _vm._e(),
                            item.direction === "to_device" && item.is_sent
                              ? _c("i", {
                                  class: _vm.$config.icons.states.none
                                })
                              : _vm._e(),
                            item.direction === "to_device" && item.is_sent
                              ? _c("i", {
                                  class: _vm.$config.icons.states.success
                                })
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dt.timestampToLocalDateTime(
                                    item.timestamp
                                  )
                                ) +
                                " - " +
                                _vm._s(item.text.substring(0, 200)) +
                                " "
                            ),
                            item.command && Object.keys(item.command).length
                              ? _c("i", {
                                  staticClass: "row-icon-button",
                                  class: _vm.$config.icons.general.file,
                                  on: {
                                    click: function($event) {
                                      return _vm.showCommandData(item)
                                    }
                                  }
                                })
                              : _vm._e(),
                            item.data && Object.keys(item.data).length
                              ? _c("i", {
                                  staticClass: "row-icon-button",
                                  class: _vm.$config.icons.general.file,
                                  on: {
                                    click: function($event) {
                                      return _vm.showCommandData(item)
                                    }
                                  }
                                })
                              : _vm._e()
                          ]),
                          item.response
                            ? _c(
                                "div",
                                { staticClass: "command-item-response" },
                                [
                                  _c("i", {
                                    class: _vm.$config.icons.general.info
                                  }),
                                  _vm._v(" " + _vm._s(item.response) + " ")
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    _c("ScrollTrigger", {
                      attrs: { threshold: 100 },
                      on: { scroll_threshold: _vm.onScrollCommands }
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-command-queue",
            centered: "",
            size: "xl",
            title: "Command Queue"
          }
        },
        [
          _c("admin-device-command-queue", {
            attrs: { device_imei: _vm.deviceInternal.device_imei }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-commands-help",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Command Help"
          }
        },
        [
          _c("device-command-help", {
            attrs: { "modal-id": "modal-commands-help" },
            on: { change: _vm.selectCommand }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-view-command",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "View Command Details"
          }
        },
        [
          _vm.selectedCommand
            ? _c(
                "div",
                { staticClass: "flex-column" },
                [
                  _c("div", { staticClass: "flex-row" }, [
                    _c("div", [
                      _c("label", [_vm._v("Id: ")]),
                      _vm._v(" " + _vm._s(_vm.selectedCommand.id) + " ")
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Direction: ")]),
                      _vm._v(" " + _vm._s(_vm.selectedCommand.direction) + " ")
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Timestamp: ")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dt.timestampToLocalDateTime(
                              _vm.selectedCommand.timestamp
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Command Text: ")]),
                      _vm._v(" " + _vm._s(_vm.selectedCommand.text) + " ")
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Command Method: ")]),
                      _vm._v(" " + _vm._s(_vm.selectedCommand.method) + " ")
                    ])
                  ]),
                  _c("label", [_vm._v("Command Data:")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCommandJson,
                        expression: "selectedCommandJson"
                      }
                    ],
                    staticClass: "w-100",
                    attrs: {
                      type: "text",
                      rows: "12",
                      id: "rawJSON",
                      placeholder: "Command JSON",
                      disabled: ""
                    },
                    domProps: { value: _vm.selectedCommandJson },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.selectedCommandJson = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("modal-view-command")
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }