<template>
  <div class="device-list">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="filter-bar" v-if="!hideFilter">
      <div>
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
            class="text-input"
            @keypress="inputPress"
          ></b-form-input>
          <b-input-group-append>
            <b-button v-if="!hideAdvanced" @click="showAdvanced = !showAdvanced">Advanced</b-button>
            <b-button
              v-if="filterMethod === 'remote'"
              @click="applyFilter"
              variant="primary"
              >Apply</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <div v-show="showAdvanced" class="">
          <search-filters
            :filters="filters"
            :default-settings="filterDefaults"
            @change="advFilterChange"
            @press-enter="applyFilter"
          ></search-filters>
        </div>
      </div>
    </div>

    <div class="green-divider" v-if="!hideFilter"></div>
    <!--    Bootstrap Table-->
    <b-table
      striped
      hover
      :items="deviceList"
      :fields="deviceFields"
      :select-mode=selectMode
      :selectable="selectable"
      @row-selected="tableSelectEvent"
      selected-variant="success"
      :filter="filterMethod === 'local' ? filter : undefined"
      :filterIncludedFields="filterOn"
      :current-page="currentPage"
      :per-page="perPage"
      td-class="admin-device-table-data"
      th-class="admin-device-table-header"
      v-on:filtered="filterChange"
      ref="deviceTable"
    >
      <!--Select All Header Element-->
      <template v-slot:head(selected)="row">
<!--        <b-form-checkbox-->
<!--          v-model="selectAll"-->
<!--          v-on:change="toggleSelectAll"-->
<!--        ></b-form-checkbox>-->
      </template>
      <!--Select Checkbox Row Element-->
      <template v-slot:cell(selected)="row">
        <b-form-checkbox
          v-model="row.item.selected"
          v-on:change="selectChange(row.item, $event)"
        ></b-form-checkbox>
      </template>
      <!-- Geolimit -->
      <template v-slot:cell(geolimit_status)="row">
        <p v-if="row.item.geolimit_status.is_active">On</p>
        <p v-if="!row.item.geolimit_status.is_active">Off</p>
      </template>
<!--Billable Checkbox Row Element-->
      <template v-slot:cell(billable)="row">
        <b-form-checkbox v-model="row.item.billable" v-on:change="billableChange(row.item, $event)"
                         v-b-tooltip.hover title="Count Device in Invoices?"></b-form-checkbox>
      </template>+
      <!--Archived Checkbox Row Element-->
      <template v-slot:cell(archived)="row">
        <b-form-checkbox v-model="row.item.archived" disabled
                         v-b-tooltip.hover title="Is Archived?"></b-form-checkbox>
      </template>
      <!-- Show Whether the device has fuel data -->
      <template v-slot:cell(has_fuel_data)="row">
        <p v-if="row.item.has_fuel_data">Yes</p>
        <p v-if="!row.item.has_fuel_data">No</p>
      </template>
      <!-- Show whether the device has a subscription -->
      <template v-slot:cell(has_subscription)="row">
        <p v-if="row.item.has_subscription">Yes</p>
        <p v-if="!row.item.has_subscription">No</p>
      </template>
      <!--Actions Row Element-->
      <template v-slot:cell(actions)="row">
        <i
          class="row-icon-button row-action"
          :class="$config.icons.general.edit"
          @click="deviceActionEdit(row.item)"
          v-b-tooltip.hover
          title="Edit Device"
        ></i>
        <i
          v-if="row.item.user_id"
          class="row-icon-button row-action"
          :class="$config.icons.general.unassign"
          v-b-tooltip.hover
          title="Unassign"
          @click="deviceActionUnassign(row.item)"
        ></i>
        <i
          v-if="!row.item.user_id"
          class="row-icon-button row-action"
          :class="$config.icons.general.assign"
          v-b-tooltip.hover
          title="Assign"
          @click="deviceActionAssign(row.item)"
        ></i>
        <i
          class="row-icon-button row-action"
          :class="$config.icons.device.unhealthy"
          v-b-tooltip.hover
          title="Healthcheck"
          @click="deviceActionHealthcheck(row.item)"
        ></i>
        <i
          class="row-icon-button row-action"
          :class="$config.icons.nav.fleet"
          v-b-tooltip.hover
          title="Device Page"
          @click="deviceActionFleet(row.item)"
        ></i>
        <i
          class="row-icon-button-danger row-action ml-2"
          :class="$config.icons.general.remove"
          @click="deviceActionDelete(row.item)"
          v-b-tooltip.hover
          title="Delete Device"
        ></i>
      </template>
      <!--Device Details row-detail element-->
      <template v-slot:row-details="row">
        <b-card>
          Device List Details
          <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
        </b-card>
      </template>
    </b-table>
    <p v-if="loadingMoreDevices">Loading more devices...</p>
    <div class="green-divider" v-if="!hideFilter"></div>
    <div class="page-bar" v-if="!hidePagination">
      <b-form-select
        v-model="perPage"
        id="perPageSelect"
        size="sm"
        :options="pageOptions"
        class="page-select"
      >
      </b-form-select>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="page-buttons"
      ></b-pagination>
    </div>

    <!--   Device Edit Modal Template-->
    <b-modal
      id="modal-edit-device"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Edit Device"
      no-close-on-backdrop
    >
      <admin-edit-device
        is-modal
        modal="modal-edit-device"
        :device="currentDevice"
        v-on:save="onSave()"
      ></admin-edit-device>
    </b-modal>

    <!--   Device Healthcheck Modal Template-->
    <b-modal
      id="modal-healthcheck-device"
      centered
      class="modal-content"
      size="m"
      hide-footer
      title="Healthcheck Device"
    >
      <admin-healthcheck-device
        :device="currentDevice"
        hide-button
      ></admin-healthcheck-device>
      <b-button
        class="button"
        @click="$bvModal.hide('modal-healthcheck-device')"
        >Close</b-button
      >
    </b-modal>

    <!--   Assign Owner Modal Template-->
    <b-modal
      id="modal-assign-owner"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Assign Owner"
    >
      <admin-user-list
        is-modal
        modal="modal-assign-owner"
        single-select-mode
        @change="onOwnerChange"
      ></admin-user-list>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-assign-owner')"
          >Cancel</b-button
        >
        <b-button class="button" @click="assignOwner()">Assign Owner</b-button>
      </div>
    </b-modal>

    <!--  Alarm State Modal Template-->
    <b-modal
      id="modal-alarm-device"
      centered
      class="modal-content"
      size="m"
      hide-footer
      title="Alarm State"
    >
      <div class="row" v-if="currentDevice">
        <label class="device-label">Alarm Change Timestamp:</label>
        <div>{{ formatTimestamp(currentDevice.alarm_changed_time_utc) }}</div>
      </div>
      <div class="row" v-if="currentDevice">
        <b-form-checkbox
          size="lg"
          name="check-button"
          switch
          v-model="currentDevice.alarm_status"
        >
          Alarm State
        </b-form-checkbox>
      </div>
      <b-button class="button" @click="$bvModal.hide('modal-alarm-device')"
        >Close</b-button
      >
    </b-modal>
  </div>
</template>
<script>
import AdminHealthcheckDevice from './AdminHealthcheckDevice'
import AdminEditDevice from './AdminEditDevice'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'
import LoadingBox from '../helpers/LoadingBox'
import moment from 'moment'
import SearchFilters from '../shared/SearchFilters'

export default {
  name: 'admin-device-list',
  components: {
    SearchFilters,
    LoadingBox,
    AdminHealthcheckDevice,
    AdminEditDevice,
    AdminUserList: () => import('./AdminUserList')
  },
  props: {
    devices: Array, // If provided then the table will show these devices, rather than a list from the API. Also disabled advanced filters
    hideFilter: Boolean, // Hides the entire filter bar, for embedded tables.
    hidePagination: Boolean, // Hide the pagination controls
    hideCheckboxes: Boolean, // Hide the multi-select controls
    hideAdvanced: Boolean, // Hide the advanced filter options, leaving the plain text filter.
    showFields: Array, // An Array of strings which match the 'key' values for possible fields. (see deviceFields),
    selectMode: String, // Select Mode to pass BvTable
    archivedMode: Boolean, // Show ONLY archived devices,
    filterDefaults: Object // Set specific filter default values
  },
  data: function () {
    return {
      loading: true,
      // Assignment Temporary variables
      tempOwner: null,
      // Table Filtering
      filterMethod: 'remote', // Filter Locally or remotely at the server-side
      filter: '',
      filterOn: [
        'imei',
        'device_id',
        'name',
        'plant_num',
        'registration',
        'device_phone',
        'device_type'
      ],
      // Table Pagination (local pagination - on the browser)
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      selectAll: false,
      currentDevice: null,
      filteredDeviceList: null,
      showAdvanced: false,
      selectable: false,
      deviceFields: [],
      masterFields: [
        {
          key: 'selected',
          label: '-'
        },
        { key: 'device_imei',
          label: 'IMEI#',
          sortable: true
        },
        { key: 'device_type',
          label: 'Type',
          sortable: true,
          formatter: this.formatDeviceType
        },
        { key: 'device_code',
          label: 'Code',
          sortable: true
        },
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'device_name',
          label: 'Name',
          sortable: true
        },
        { key: 'plant_num',
          label: 'Plant',
          sortable: true
        },
        { key: 'registration',
          label: 'Rego',
          sortable: true
        },
        { key: 'device_phone',
          label: 'Phone #',
          sortable: true
        },
        { key: 'billable',
          label: 'Billable',
          sortable: true
        },
        { key: 'archived',
          label: 'Archived',
          sortable: true
        },
        { key: 'has_fuel_data',
          label: 'Has Fuel Data?',
          sortable: true
        },
        {
          key: 'has_subscription',
          label: 'Has Subscription?',
          sortable: true
        },
        { key: 'location.datetime',
          label: 'Last Report',
          sortable: true,
          formatter: this.formatTimestamp
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      deviceList: [],
      // Advanced Filtering Options
      filterOpts: {},
      filters: [
        {
          label: 'Filter By Name',
          type: 'string',
          placeholder: 'Name Must Include...',
          fieldName: 'device_name'
        },
        {
          label: 'Filter By Code',
          type: 'string',
          placeholder: 'Device Code Must Include...',
          fieldName: 'device_code'
        },
        {
          label: 'Filter By Type',
          type: 'string',
          placeholder: 'Device type Must be...',
          fieldName: 'device_type'
        },
        {
          label: 'Filter By Phone',
          type: 'string',
          placeholder: 'Phone # must include...',
          fieldName: 'device_phone'
        },
        {
          label: 'Filter By IMEI',
          type: 'string',
          placeholder: 'Device IMEI Must Include...',
          fieldName: 'device_imei'
        },
        {
          label: 'Filter By Rego',
          type: 'string',
          placeholder: 'Registration must include...',
          fieldName: 'registration'
        },
        {
          label: 'Filter By Plant',
          type: 'string',
          placeholder: 'Plant must include...',
          fieldName: 'plant_num'
        },
        {
          label: 'Filter by Icon',
          type: 'select',
          options: [
            { value: '', text: 'None' },
            { value: 'Car', text: 'Car' },
            { value: 'Boat', text: 'Boat' },
            { value: 'Caravan', text: 'Caravan' },
            { value: 'Excavator', text: 'Excavator' },
            { value: 'Motorbike', text: 'Motorbike' },
            { value: 'SemiTruck', text: 'SemiTruck' },
            { value: 'Tractor', text: 'Tractor' },
            { value: 'Truck', text: 'Truck' }
          ],
          fieldName: 'icon'
        },
        {
          label: 'is Geolimited',
          type: 'boolean',
          fieldName: 'geolimit_status'
        },
        {
          label: 'Alarm Status',
          type: 'boolean',
          fieldName: 'alarm_status'
        },
        {
          label: 'Livetrack Status',
          type: 'boolean',
          fieldName: 'livetrack_status'
        },
        {
          label: 'Vibtration Alarm Enabled',
          type: 'boolean',
          fieldName: 'vibration_alarm_active'
        },
        {
          label: 'Archived',
          type: 'boolean',
          fieldName: 'archived'
        }
      ],
      loadingMoreDevices: false,
      deviceTypes: {
        device_sinocastel_213lu: 'SIN_213LU',
        device_benway_gv20: 'BEN_GV20',
        generic_object_tracker: 'GEN_OBJ',
        generic_vehicle_tracker: 'GEN_VEH',
        device_kingwo_nt06e: 'KGW_NT06E'
      }
    }
  },
  async mounted () {
    if (this.hideCheckboxes) {
      this.deviceFields = this.deviceFields.filter(x => {
        return x.key !== 'selected'
      })
    }
    if (this.selectMode) {
      this.selectable = true
    }
    // If a list of fields to display has been provided, filter to only show those fields.
    if (this.showFields) {
      this.updateFields(this.showFields)
    } else {
      this.deviceFields = this.masterFields
    }

    if (this.filterDefaults) {
      this.filterOpts = this.filterDefaults
    }
    // Check if the 'devices' prop has been provided, if so use that rather than fetching a full list.
    if (this.devices) {
      // TODO - Handle a string of device IDs being passed as this seems to be the standard
      this.updateTableFromJSON(this.devices)
      this.showAdvanced = false
      this.filterMethod = 'local'
    } else {
      await this.refreshDeviceList()
    }
    this.loading = false
  },
  methods: {
    onSave: async function () {
      await this.refreshDeviceList()
    },
    refreshDeviceList: async function () {
      if (this.filter || Object.keys(this.filterOpts).length > 0) {
        this.applyFilter()
      } else {
        let deviceTotalData = await DataProvider.getAdminDeviceCount(this.archivedMode)
        if (deviceTotalData.success) {
          this.totalRows = deviceTotalData.data.number_of_devices
        } else {
          ErrorHelper.displayDataErrorToast(deviceTotalData)
        }
        this.deviceList = this.dataPageProvider
        this.$refs.deviceTable.refresh()
      }
    },
    dataPageProvider: async function (ctx) {
      let resp = await DataProvider.getAdminDeviceListPaginated({
        start: (ctx.currentPage - 1) * ctx.perPage,
        page_size: ctx.perPage,
        archived: this.archivedMode
        // fake_sleep: 5  // You can pass a fake_sleep here and the server will wait that many seconds before replying - for testing
      })
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        return resp.data.devices
      }
    },
    updateTableFromJSON: function (deviceData) {
      this.deviceList.push(...deviceData)
    },
    updateFields: function (fieldList) {
      this.deviceFields = this.masterFields.filter(field =>
        fieldList.includes(field.key)
      )
    },
    formatTimestamp: function (timestamp) {
      return moment.utc(timestamp).format('YYYY-MM-DD HH:mm')
    },
    deviceActionDelete: async function (device) {
      let res = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete ${device.device_name} ?`,
        {
          title: 'Confirm Delete',
          okVariant: 'danger',
          centered: true
        }
      )
      if (res) {
        let response = await DataProvider.adminArchiveDevice(device.device_imei)
        if (response.success) {
          AlertHelper.successToast('The Device was successfully deleted.', 'Delete Successful')
          await this.refreshDeviceList()
        } else {
          ErrorHelper.displayDataErrorToast(response)
        }
      }
    },
    deviceActionUnassign: async function (device) {
      let res = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to Unassign ${device.device_name} ?`,
        {
          title: 'Confirm Unassignment',
          okVariant: 'warning',
          centered: true
        }
      )
      if (res) {
        let result = await DataProvider.adminUnAssignDevice(device.device_imei)
        if (result.success) {
          AlertHelper.successToast(
            'Device has been unassigned successfully!',
            'Device Unassigned.'
          )
          await this.refreshDeviceList()
        } else {
          ErrorHelper.displayDataErrorToast(result)
        }
      }
    },
    deviceActionAssign: async function (device) {
      this.currentDevice = device
      this.$bvModal.show('modal-assign-owner')
    },
    // Event Called when a row is selected from the Assignment Dialog
    onOwnerChange: async function (newOwner) {
      this.tempOwner = newOwner[0]
    },
    assignOwner: async function () {
      if (!this.tempOwner) {
        ErrorHelper.displayGeneralWarningToast(
          'You have no selected an owner to assign the device to.',
          'No User Selected'
        )
        return
      }
      let result = await DataProvider.adminAssignDevice(
        this.currentDevice.device_imei,
        this.tempOwner.email
      )
      if (result.success) {
        AlertHelper.successToast(
          'Device Successfully Assigned to ' + this.tempOwner.email,
          'Device Assigned'
        )
        this.$bvModal.hide('modal-assign-owner')
        await this.refreshDeviceList()
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    },
    deviceActionHealthcheck: function (device) {
      this.currentDevice = device
      this.$bvModal.show('modal-healthcheck-device')
    },
    deviceActionEdit: function (device) {
      this.currentDevice = device
      console.log(this.currentDevice)
      this.$bvModal.show('modal-edit-device')
    },
    deviceActionFleet: function (device) {
      this.currentDevice = device
      let routeData = this.$router.resolve(`/device/${device.device_imei}`)
      window.open(routeData.href, '_blank')
    },
    toggleSelectAll: function (event) {
      let selectedDevices

      if (this.filteredDeviceList) {
        // If we've filtered the device list, respect the filter.
        if (event) {
          this.filteredDeviceList.forEach(x => {
            x.selected = true
          })
          selectedDevices = this.filteredDeviceList
        } else {
          this.clearSelectAll()
          selectedDevices = []
        }
      } else {
        if (event) {
          this.deviceList.forEach(x => {
            x.selected = true
          })
          selectedDevices = this.deviceList
        } else {
          this.clearSelectAll()
          selectedDevices = []
        }
      }
      this.$emit('change', selectedDevices)
    },
    tableSelectEvent: function (x) {
      this.$emit('row-selected', x)
    },
    selectChange: function (device, event) {
      device.selected = event // Manually update select state, otherwise result will be a 'click behind'
      let selectedDevices
      if (Array.isArray(this.deviceList)) {
        selectedDevices = this.deviceList.filter(x => {
          return x.selected
        })
      } else {
        selectedDevices = this.$refs.deviceTable.localItems.filter(x => {
          return x.selected
        })
      }
      this.$emit('change', selectedDevices)
    },
    clearSelectAll: function () {
      if (Array.isArray(this.deviceList)) {
        this.deviceList.forEach(x => {
          x.selected = false
        })
      } else {
        this.$refs.deviceTable.localItems.forEach(x => {
          x.selected = false
        })
      }
      this.selectAll = false
    },
    billableChange: function (device, event) {
      let updateData = {
        billable: event
      }
      this.saveDevice(device.device_imei, updateData)
    },
    saveDevice: async function (imei, deviceData) {
      let resp = await DataProvider.adminUpdateDevice(imei, deviceData)
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        AlertHelper.successToast('Device Changes Saved', 'Update Successful')
        this.refreshDeviceList()
      }
    },
    filterChange: function (filteredDevices) {
      this.clearSelectAll()
      this.$emit('change', [])
      this.filteredDeviceList = filteredDevices
    },
    advFilterChange: function (newFilterOpts) {
      this.filterOpts = newFilterOpts
    },
    applyFilter: async function () {
      let responseData
      if (this.showAdvanced) {
        responseData = await DataProvider.getAdminDeviceList(
          this.filter,
          this.filterOpts
        )
      } else {
        responseData = await DataProvider.getAdminDeviceList(
          this.filter,
          this.filterDefaults
        )
      }
      // Should only be called when using 'remote' filtering, as control is hidden otherwise.
      this.deviceList = []
      if (!responseData.success) {
        ErrorHelper.displayDataErrorToast(responseData)
        return
      }
      // TODO - Check this format
      this.updateTableFromJSON(responseData.data)
      this.totalRows = responseData.data.length
    },
    inputPress: function (event) {
      if (event.charCode === 13) {
        this.applyFilter()
      }
    },
    filterEnter: function () {
      this.applyFilter()
    },
    formatDeviceType: function (type) {
      if (this.deviceTypes.hasOwnProperty(type)) {
        return this.deviceTypes[type]
      } else {
        return type
      }
    }
  },
  watch: {
    devices: function (newVal, oldVal) {
      this.updateTableFromJSON(newVal)
    },
    showFields: function (newVal, oldVal) {
      this.updateFields(newVal)
    }
  },
  computed: {
    tableData () {
      if (this.devices) {
        return this.devices
      } else {
        return this.$refs.deviceTable.localItems
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../variables";

.user-list {
  position: relative;
}

.filter-bar {
  display: flex;
  flex-direction: row;
}

.page-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
}

.page-select {
  width: 10%;
  min-width: 50px;
}

.page-buttons {
  width: 25%;
  min-width: 150px;
}

.row-action {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.advanced-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px;
  padding: 3px;
  background: $theme-color-background-1;
  border: 1px solid $theme-color-primary-3;
}

.filter-label {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0px 5px;
}

.sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: calc(1.5em + 0.5rem + 2px);
}
</style>
