<template>
  <div class="admin-edit-213LU">
    <div class="section-container">
      <div class="green-label">Device Configuration</div>
      <div class="green-divider"></div>
      <b-textarea class="w-100" v-model="configText" rows="6"></b-textarea>
    </div>
    <div class="section-container">
      <div class="green-label">Device State</div>
      <div class="green-divider"></div>
      <b-textarea class="w-100" v-model="stateText" rows="6"></b-textarea>
    </div>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'

export default {
  name: 'admin-device-state-213LU',
  components: {},
  props: {
    device: Object
  },
  data: function () {
    return {
      deviceIMEI: '',
      deviceInternal: {},
      deviceState: null,
      configText: null,
      stateText: null
    }
  },
  async created () {
    if (this.device) {
      // If we have been passed the device prop, then populate based on that.
      this.deviceInternal = { ...this.device } // Take a shallow copy of the device to avoid editing parent's data
      this.deviceIMEI = this.device.device_imei
      this.isNewDevice = false
      this.deviceState = await this.loadDeviceState(this.deviceIMEI)
      this.updateConfig(this.deviceState.configuration_data)
      this.updateState(this.deviceState.state_data)
      console.log('Device State Data: ', this.deviceState)
    }
  },
  methods: {
    loadDeviceState: async function (deviceImei) {
      let resp = await DataProvider.getDeviceProperty(deviceImei, 'device_state')
      if (resp.success) {
        return resp.data.device_state
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    updateConfig: function (data) {
      this.configText = JSON.stringify(data, null, 2)
    },
    updateState: function (data) {
      this.stateText = JSON.stringify(data, null, 2)
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.admin-edit-213LU {
  color: $text-color-invert;
}




</style>
