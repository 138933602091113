<template>
  <div class="admin-command-213LU">
        <b-input-group size="sm" class="mb-3">
            <label class="green-label mt-0">Command Type</label>
            <!--            <b-button @click="$bvModal.show('modal-commands-help')">Commands</b-button>-->
            <b-form-select id="command_type" :options="commandTypes" v-model="commandType" ></b-form-select>
        </b-input-group>

<!--          SMS Command-->
          <b-input-group v-if="commandType==='sms'" size="sm" class="justify-content-around">
            <b-input-group-prepend>
              <b-button @click="$bvModal.show('modal-sms-commands-help')">Templates</b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="smsCommandText"
              type="text"
              id="filterInput"
              placeholder="Enter Command to Send"
              debounce="500"
              class="text-input"
              @keydown.native="keydownHandler"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="sendCommand('sms', smsCommandText)" variant="danger">Send via SMS</b-button>
            </b-input-group-append>
          </b-input-group>

<!--          TLV SET Command-->
          <b-input-group v-if="commandType==='2001'" size="sm">
            <b-input-group size="sm" class="tlv-table">
              <label class="green-label mt-0">Select TLV Codes</label>
              <b-input-group size="sm" prepend="Filter:">
                <b-form-input
                  v-model="tlvFilter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search"
                  debounce="500"
                  class="text-input"
                ></b-form-input>
              </b-input-group>
              <div class="tlv-table-inner w-100">
                <b-table striped hover :items="tlvWriteableCodes" :fields="tlvFields" responsive
                         :filter="tlvFilter" small>
                  <!--Select Row Element-->
                  <template v-slot:cell(select)="row">
                    <b-button v-if="!isSelected(row.item)"
                              @click="addTLVCode(row.item)">+</b-button>
                    <i v-if="isSelected(row.item)"
                       class="fa fa-check icon-button"></i>
                  </template>
                </b-table>

              </div>
            </b-input-group>
            <div size="sm" class="w-100">
                <label class="green-label mt-0">Selected Codes</label>
              <div class="code-container">
                <div v-for="(tlvCode, idx) of tlvCodesSelected" v-bind:key="idx" class="code-item">
                  <div class="green-label mt-0">Code - {{tlvCode.text}}</div>
                  <div class="code-item-inner">
                    <div v-for="(tlvAttribute, attributeIdx) of getTLVAttributes(tlvCode)" v-bind:key="attributeIdx"
                    class="w-100">
                      <!--                  Booleans -->
                      <b-form-checkbox v-if="tlvAttribute.type === 'boolean' || tlvAttribute.type === 'bool'"
                                       size="sm" name="check-button" switch
                                       v-model="tlvSetAttributes[tlvCode.value][attributeIdx]"> {{tlvAttribute.name}}
                      </b-form-checkbox>
                      <!--                  Bitfields (*sigh*)-->
                      <div title="Bit Field" v-if="tlvAttribute.type === 'bitfield'">
                        <b-form-checkbox
                          v-for="(field_name, bitfieldIdx) of tlvAttribute.field_names" v-bind:key="bitfieldIdx"
                          size="sm" name="check-button"
                          v-model="tlvSetAttributes[tlvCode.value][attributeIdx][bitfieldIdx]"
                        >
                          {{field_name}}
                        </b-form-checkbox>
                      </div>
                      <!--                  Numbers -->
                      <b-input-group v-if="tlvAttribute.type === 'int' || tlvAttribute.type === 'float'"
                                     :append="tlvAttribute.unit">
                        <label class="green-label mt-0">{{tlvAttribute.name}}</label>
                        <b-input
                          size="sm" name="check-button" type="number"
                          :max="tlvAttribute.max"
                          :min="tlvAttribute.min"
                          v-model="tlvSetAttributes[tlvCode.value][attributeIdx]"> {{tlvAttribute.name}}
                        </b-input>
                      </b-input-group>
                      <!--                  String -->
                      <b-input-group v-if="tlvAttribute.type === 'string'"
                                     :append="tlvAttribute.unit">
                        <label class="green-label mt-0">{{tlvAttribute.name}}</label>
                        <b-input
                          size="sm" name="check-button" type="text"
                          :max="tlvAttribute.max"
                          :min="tlvAttribute.min"
                          v-model="tlvSetAttributes[tlvCode.value][attributeIdx]"> {{tlvAttribute.name}}
                        </b-input>
                      </b-input-group>
                      <!--                  Index (selection) -->
                      <b-input-group v-if="tlvAttribute.type === 'index'"
                                     :append="tlvAttribute.unit">
                        <label class="green-label mt-0">{{tlvAttribute.name}}</label>
                        <b-select
                          size="sm" name="check-button" :options="tlvAttribute.options"
                          v-model="tlvSetAttributes[tlvCode.value][attributeIdx]"> {{tlvAttribute.name}}
                        </b-select>
                      </b-input-group>
                      <b-input-group v-if="tlvAttribute.type === 'pid-type-array'" class="flex-grow-1">
                        <label class="green-label mt-0">{{tlvAttribute.name}}</label>
                        <b-input
                          class="flex-grow-1"
                          size="lg" name="check-button" type="text"
                          v-model="tlvSetAttributes[tlvCode.value][attributeIdx]">
                        </b-input>
                        <div>PID Hex Codes, No spaces</div>
                      </b-input-group>
                    </div>
                    <b-button variant="danger" @click="removeTLVCode(tlvCode)">X</b-button>
                  </div>
                </div>
              </div>

            </div>
            <b-button @click="sendTLVSetCommand()" variant="warning">Send TLV SET</b-button>

          </b-input-group>

          <!--          TLV QUERY Command-->
          <b-input-group v-if="commandType==='2002'" size="sm">
              <b-input-group size="sm" class="tlv-table w-73">
                <label class="green-label mt-0 mb-2">Select TLV Codes</label>
                <b-input-group size="sm" prepend="Filter:">
                  <b-form-input
                    v-model="tlvFilter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    debounce="500"
                    class="text-input"
                  ></b-form-input>
                </b-input-group>
                <div class="tlv-table-inner">
                  <b-table striped hover :items="tlvCodeOptions" :fields="tlvFields" responsive
                           :filter="tlvFilter" small>
                    <!--Select Row Element-->
                    <template v-slot:cell(select)="row">
                      <b-button v-if="!isSelected(row.item)"
                                @click="addTLVCode(row.item)">+</b-button>
                      <i v-if="isSelected(row.item)"
                         class="fa fa-check icon-button"></i>
                    </template>
                  </b-table>
                </div>
              </b-input-group>
              <b-input-group size="sm" class="w-25">
                <div>
                  <label class="green-label mt-0">Selected Codes</label>
                  <b-form-select v-model="tlvCodeRemove" :select-size="8" :options="tlvCodesSelected" multiple>
                  </b-form-select>
                  <b-button @click="removeTLVCode()" variant="danger" :disabled="!tlvCodeRemove">Remove</b-button>
                </div>
              </b-input-group>
            <b-button @click="sendTLVQueryCommand()" variant="warning">Send TLV Query</b-button>
          </b-input-group>


          <!--          Location Query Commands-->
          <b-input-group v-if="commandType==='3001'" size="sm" class="justify-content-around">
            <b-button @click="sendLocationCommand()" variant="warning">Send Location Query</b-button>
          </b-input-group>

          <!--          DTC Clear Commands-->
          <b-input-group v-if="commandType==='3002'" size="sm" class="justify-content-around">
            <b-button @click="sendClearDTCCommand()" variant="warning">Send Clear DTC</b-button>
          </b-input-group>

          <!--          Factory Reset Commands-->
          <b-input-group v-if="commandType==='3003'" size="sm" class="justify-content-around">
            <b-button @click="sendFactoryReset()" variant="danger">Send Factory Reset</b-button>
          </b-input-group>

          <!--          Custom Command-->
          <b-input-group v-if="commandType==='custom'" size="sm">
            <b-form-textarea
              v-model="jsonCommandText"
              type="text"
              rows="6"
              id="rawJSON"
              placeholder="Enter JSON Data"
              class="text-input"
            ></b-form-textarea>
            <b-input-group-append>
              <b-button @click="sendJSONCommand()" variant="warning">Send</b-button>
            </b-input-group-append>
          </b-input-group>

<!--          <b-input-group-append>-->
<!--            <b-button @click="sendCommand('data')" variant="primary">Send via Data</b-button>-->

<!--            <b-button @click="$bvModal.show('modal-json-command')" variant="warning">Send JSON</b-button>-->
<!--          </b-input-group-append>-->
    <b-modal
      id="modal-sms-commands-help"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Command Help"
    >
      <device-command-help modal-id="modal-sms-commands-help" :command-list="smsCommandList"
                           @change="selectSMSCommand"></device-command-help>
    </b-modal>
  </div>
  <!--   Command Help Modal Template-->
</template>

<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as encoder from '@/components/helpers/SinocastelEncoder'
import DeviceCommandHelp from '@/components/admin/device_command_types/DeviceCommandHelp'
import * as commandList from '@/components/admin/device_command_types/sino_sms_commands.json'
import moment from 'moment'

export default {
  name: 'admin-command-213LU',
  components: {
    DeviceCommandHelp
  },
  props: {
    device: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      currentCommand: '',
      filter: '',
      deviceInternal: {
        device_id: 1,
        imei: '351608083567144',
        device_type: null
      },
      tlvFilter: '',
      tlvFields: [
        { key: 'select',
          label: '-',
          sortable: false,
          thStyle: {
            width: '80px'
          }

        },
        { key: 'code',
          label: '#',
          sortable: true
        },
        { key: 'short_name',
          label: 'Short Name',
          sortable: true
        },
        { key: 'name',
          label: 'Name',
          sortable: true
        }
      ],
      jsonCommandText: '',
      commandType: '2001',
      commandTypes: [
        {
          text: 'SMS Command',
          value: 'sms'
        },
        {
          text: 'Config Set',
          value: '2001'
        },
        {
          text: 'Config Query',
          value: '2002'
        },
        {
          text: 'Location Query',
          value: '3001'
        },
        {
          text: 'Clear DTC',
          value: '3002'
        },
        {
          text: 'Factory Reset',
          value: '3003'
        },
        {
          text: 'OBD Command',
          value: '300F'
        },
        {
          text: 'Custom Command',
          value: 'custom'
        }
      ],
      smsCommandText: '*' + this.device.device_imei.slice(-6),
      tlvCodeOptions: [],
      tlvCodesSelected: [],
      tlvCodeRemove: [],
      tlvConfig: null,
      tlvWriteableCodes: [],
      tlvSetAttributes: {},
      smsCommandList: commandList.commandList
    }
  },
  async created () {
    if (this.device) {
      this.deviceInternal = this.device
    }
    console.log(await this.$configService.getConfig('tlvCodes'))
    let config = await this.$configService.getConfig('tlvCodes')
    if (!config.hasOwnProperty('tlv_codes')) {
      ErrorHelper.displayGeneralErrorToast('Failed to Load TLV Codes. Some command helpers will not work!')
    } else {
      this.tlvCodeOptions = this.getTLVOptions(config.tlv_codes)
      this.tlvWriteableCodes = this.tlvCodeOptions.filter(x => !x.hasOwnProperty('read-only'))
      this.tlvConfig = this.parseTLVConfig(config.tlv_codes)
    }
  },
  methods: {
    getTLVOptions: function (tlvConfig) {
      let result = []
      for (let code in tlvConfig) {
        result.push(tlvConfig[code])
      }
      return result
    },
    getTLVAttributes: function (tlvCode) {
      console.log('code:', tlvCode)
      if (this.tlvConfig[tlvCode.value].extended) {
        console.log('TLV attributes: ', this.tlvConfig[tlvCode.value].attributes)
        return this.tlvConfig[tlvCode.value].attributes
      } else {
        return null
      }
    },
    addTLVCode: function (code) {
      console.log(code)
      this.tlvSetAttributes[code.code] = code.attributes.map(x => {
        if (x.type === 'bitfield') {
          if (x.hasOwnProperty('field_defaults')) {
            return x.field_defaults
          } else {
            return x.field_names.map(i => 0)
          }
        } else {
          if (x.hasOwnProperty('default')) {
            return x.default
          } else {
            return 0
          }
        }
      })
      console.log('Attribute Defaults: ', this.tlvSetAttributes)
      this.tlvCodesSelected.push({
        text: `${code.code}-${code.short_name}`,
        value: code.code
      })
    },
    removeTLVCode: function (tlvCode) {
      console.log(tlvCode)
      if (tlvCode) {
        this.tlvCodeRemove = [tlvCode.value]
      }
      this.tlvCodeRemove.forEach(removeCode => {
        this.tlvCodesSelected = this.tlvCodesSelected.filter(x => String(x.value) !== removeCode)
        this.tlvSetAttributes[removeCode] = null
      })
      this.tlvCodeRemove = []
      console.log(this.tlvCodesSelected)
    },
    isSelected: function (code) {
      if (this.tlvCodesSelected) {
        return this.tlvCodesSelected.some(x => x.value === code.code)
      }
    },
    sendTLVQueryCommand: async function () {
      let tagArray = this.tlvCodesSelected.map(x => String(x.value)).join('')
      let data = {
        device_id: this.device.device_imei,
        protocol_id: '2002',
        query_count: this.tlvCodesSelected.length,
        tag_array: tagArray
      }
      this.sendCommand('data', data, 'Custom TLV QUERY')
    },
    parseTLVConfig: function (tlvConfig) {
      // Go through all of the TLV codes and their attributes, adding an 'options' property to 'index'
      // type attributes which can be fed to a select component.
      for (let code in tlvConfig) {
        if (tlvConfig[code].hasOwnProperty('attributes')) {
          tlvConfig[code].attributes.forEach(x => {
            if (x.type === 'index') {
              x.options = []
              for (let idx in x.index) {
                x.options.push({
                  value: idx,
                  text: x.index[idx]
                })
              }
            }
          })
        }
      }
      return tlvConfig
    },
    sendTLVSetCommand: async function () {
      let data = this.createTLVSetData()
      this.sendCommand('data', data, 'Custom TLV Set')
    },
    createTLVSetData: function () {
      let base = {
        device_id: this.device.device_imei,
        protocol_id: '2001',
        tlv_count: 0,
        tlv_array: []
      }
      console.log('Selected Codes: ', this.tlvCodesSelected)
      for (let tlvCode of this.tlvCodesSelected) {
        let tlvArray = this.encodeTLV(this.tlvSetAttributes[tlvCode.value], this.tlvConfig[tlvCode.value])

        base.tlv_array.push({
          tag: tlvCode.value,
          length: tlvArray.length / 2, // Bytes, so hex length / 2
          value_array: tlvArray
        })
      }
      base.tlv_count = base.tlv_array.length
      return base
    },
    encodeTLV: function (values, tlvCodeConfig) {
      let valArray = ''
      tlvCodeConfig.attributes.forEach((attribute, idx) => {
        valArray = valArray + encoder.encodeValue(values[idx], attribute)
      })
      return valArray
    },
    sendCommand: async function (sendingMethod, data, commandText) {
      console.log('COMMAND: ', data, commandText)
      let commandSend = await DataProvider.sendDeviceCommands(this.device.device_imei, data, sendingMethod, commandText)
      if (commandSend.success) {
        console.log('Command Response: ', commandSend)
        this.$bvToast.toast('Command sent to device!',
          {
            title: 'Command Registered',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        this.$emit('change')
      } else {
        ErrorHelper.displayDataErrorToast(commandSend)
      }
    },
    sendJSONCommand: async function () {
      let data
      try {
        console.log(this.jsonCommandText)
        data = JSON.parse(this.jsonCommandText.trim())
      } catch (e) {
        ErrorHelper.displayGeneralErrorToast('Unable to parse JSON!', 'Error Parsing JSON')
        console.log(e)
        return
      }
      await this.sendCommand('data', data, 'Custom JSON Command')
    },
    sendLocationCommand: function () {
      let commandData = {
        device_id: this.deviceInternal.device_imei,
        protocol_id: '3001'
      }
      this.sendCommand('data', commandData, 'Location Query')
    },
    sendClearDTCCommand: function () {
      let commandData = {
        device_id: this.deviceInternal.device_imei,
        protocol_id: '3002'
      }
      this.sendCommand('data', commandData, 'Clear DTC Command')
    },
    sendFactoryReset: async function () {
      let commandData = {
        device_id: this.deviceInternal.device_imei,
        protocol_id: '3003'
      }
      let confirm = await this.$bvModal.msgBoxConfirm('This will send a command to the device instructing it to ' +
        'restore factory settings. Are you sure you want to proceed?', {
        title: 'Confirm Factory Reset',
        okVariant: 'danger',
        centered: true
      })
      if (confirm) {
        this.sendCommand('data', commandData, 'Factory Reset')
      }
    },
    // Monitor keys looking for ENTER to be pressed.
    keydownHandler: function (event) {
      if (event.which === 13) {
        this.sendCommand('data')
      }
    },
    selectSMSCommand: function (smsCommand) {
      this.smsCommandText += smsCommand.command
      this.$bvModal.hide('modal-sms-commands-help')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.admin-command-213LU {
  width: 100%;
  color: $text-color-invert;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: $theme-color-background-2;
  border: 1px solid $theme-color-primary-2;
  border-radius: 5px;
}

.command-history {
  background: $default-content-background;
  height: calc(100% - 56px);
  overflow-y: scroll;
  max-height: 60vh;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.tlv-table {
  margin: 1% 1%;
  background: $theme-color-background-1;
}

.w-73 {
  max-width: 73%;
}

.tlv-table-inner {
  width: 100%;
  max-height: 25vh;
  overflow: auto;
}

.tlv-table-inner-small {
  max-height: 15vh;
  overflow: auto;
}

.code-container {
  display: flex;
  flex-direction: column;
}

.code-item {
  display: flex;
  flex-direction: column;
  background: $theme-color-background-1;
  border-radius: 5px;
  margin: 5px 1px;
  padding: 5px;
  border: 1px solid $theme-color-primary-2;
}

.code-item-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
