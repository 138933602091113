var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-edit-generic-vehicle" },
    [
      _c("div", { staticClass: "properties-container" }, [
        _c(
          "div",
          {
            staticClass: "property-item",
            class: { "form-error": _vm.$v.deviceInternal.device_imei.$error }
          },
          [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "device_imei" } },
              [_vm._v("IMEI")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.deviceInternal.device_imei.$model,
                  expression: "$v.deviceInternal.device_imei.$model"
                }
              ],
              staticClass: "text-field w-input",
              attrs: { type: "text", placeholder: "IMEI", id: "device_imei" },
              domProps: { value: _vm.$v.deviceInternal.device_imei.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.$v.deviceInternal.device_imei,
                    "$model",
                    $event.target.value
                  )
                }
              }
            }),
            !_vm.$v.deviceInternal.device_imei.required
              ? _c("div", { staticClass: "input-error" }, [
                  _vm._v("Field is Required")
                ])
              : _vm._e(),
            !_vm.$v.deviceInternal.device_imei.minLength
              ? _c("div", { staticClass: "input-error" }, [
                  _vm._v("Must be at least 15 characters")
                ])
              : _vm._e()
          ]
        ),
        !_vm.isNewDevice
          ? _c(
              "div",
              {
                staticClass: "property-item",
                class: {
                  "form-error": _vm.$v.deviceInternal.device_code.$error
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "device-label",
                    attrs: { for: "email_address" }
                  },
                  [_vm._v("Device Code")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.deviceInternal.device_code.$model,
                      expression: "$v.deviceInternal.device_code.$model"
                    }
                  ],
                  staticClass: "text-field w-input",
                  attrs: {
                    type: "text",
                    maxlength: "256",
                    placeholder: "Device ID",
                    id: "email_address"
                  },
                  domProps: { value: _vm.$v.deviceInternal.device_code.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.deviceInternal.device_code,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                !_vm.$v.deviceInternal.device_code.required
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Field is Required")
                    ])
                  : _vm._e(),
                !_vm.$v.deviceInternal.device_code.minLength ||
                !_vm.$v.deviceInternal.device_code.maxLength
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Must be 8 characters long")
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        !_vm.isNewDevice
          ? _c(
              "div",
              {
                staticClass: "property-item",
                class: {
                  "form-error": _vm.$v.deviceInternal.device_password.$error
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "device-label",
                    attrs: { for: "device_password" }
                  },
                  [_vm._v("Device Password")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.deviceInternal.device_password.$model,
                      expression: "$v.deviceInternal.device_password.$model"
                    }
                  ],
                  staticClass: "text-field w-input",
                  attrs: {
                    type: "text",
                    maxlength: "256",
                    placeholder: "Password",
                    id: "device_password"
                  },
                  domProps: {
                    value: _vm.$v.deviceInternal.device_password.$model
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.deviceInternal.device_password,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        !_vm.isNewDevice
          ? _c(
              "div",
              {
                staticClass: "property-item",
                class: {
                  "form-error": _vm.$v.deviceInternal.device_name.$error
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "device-label",
                    attrs: { for: "device_name" }
                  },
                  [_vm._v("Device Name")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.deviceInternal.device_name.$model,
                      expression: "$v.deviceInternal.device_name.$model"
                    }
                  ],
                  staticClass: "text-field w-input",
                  attrs: {
                    type: "text",
                    maxlength: "256",
                    placeholder: "Device Name",
                    id: "device_name"
                  },
                  domProps: { value: _vm.$v.deviceInternal.device_name.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.deviceInternal.device_name,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                !_vm.$v.deviceInternal.device_name.required
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Field is Required")
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "property-item",
            class: { "form-error": _vm.$v.deviceInternal.device_phone.$error }
          },
          [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "phone" } },
              [_vm._v("Mobile Number")]
            ),
            _c(
              "div",
              { staticClass: "flex-row" },
              [
                !_vm.isNewDevice
                  ? _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          staticClass: "text-field w-input text-input",
                          attrs: {
                            type: "text",
                            disabled: "",
                            maxlength: "256",
                            name: "Mobile Number",
                            "data-name": "Registration",
                            placeholder: "0400000000",
                            id: "phone"
                          },
                          model: {
                            value: _vm.deviceInternal.device_phone,
                            callback: function($$v) {
                              _vm.$set(_vm.deviceInternal, "device_phone", $$v)
                            },
                            expression: "deviceInternal.device_phone"
                          }
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c("b-button", [
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "row-icon-button row-action",
                                class: _vm.$config.icons.general.edit,
                                attrs: { title: "Edit Phone Number" },
                                on: {
                                  click: function($event) {
                                    return _vm.$bvModal.show(
                                      "modal-change-phone"
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isNewDevice
                  ? _c(
                      "div",
                      [
                        _c("b-form-input", {
                          staticClass: "text-field w-input text-input",
                          attrs: {
                            type: "text",
                            maxlength: "256",
                            name: "Mobile Number",
                            "data-name": "Registration",
                            placeholder: "0400000000",
                            id: "phone"
                          },
                          model: {
                            value: _vm.$v.deviceInternal.device_phone.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.deviceInternal.device_phone,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.deviceInternal.device_phone.$model"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.$v.deviceInternal.device_phone.required
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Field is Required")
                    ])
                  : _vm._e(),
                !_vm.$v.deviceInternal.device_phone.minLength
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Must be at least 10 characters long")
                    ])
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        !_vm.isNewDevice
          ? _c(
              "div",
              {
                staticClass: "property-item",
                class: {
                  "form-error": _vm.$v.deviceInternal.odometer_reading.$error
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "device-label",
                    attrs: { for: "odo_reading" }
                  },
                  [_vm._v("Odometer")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.deviceInternal.odometer_reading.$model,
                      expression: "$v.deviceInternal.odometer_reading.$model"
                    }
                  ],
                  staticClass: "text-field w-input",
                  attrs: {
                    type: "text",
                    maxlength: "256",
                    "data-name": "odometer",
                    placeholder: "000000",
                    id: "odo_reading"
                  },
                  domProps: {
                    value: _vm.$v.deviceInternal.odometer_reading.$model
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.deviceInternal.odometer_reading,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                !_vm.$v.deviceInternal.odometer_reading.decimal
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Must be a Number.")
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        !_vm.isNewDevice
          ? _c(
              "div",
              {
                staticClass: "property-item",
                class: {
                  "form-error": _vm.$v.deviceInternal.registration.$error
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "device-label",
                    attrs: { for: "registration" }
                  },
                  [_vm._v("Registration")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.deviceInternal.registration.$model,
                      expression: "$v.deviceInternal.registration.$model"
                    }
                  ],
                  staticClass: "text-field w-input",
                  attrs: {
                    type: "text",
                    maxlength: "256",
                    "data-name": "odometer",
                    placeholder: "000000",
                    id: "registration"
                  },
                  domProps: {
                    value: _vm.$v.deviceInternal.registration.$model
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.deviceInternal.registration,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        !_vm.isNewDevice
          ? _c(
              "div",
              {
                staticClass: "property-item",
                class: { "form-error": _vm.$v.deviceInternal.plant_num.$error }
              },
              [
                _c(
                  "label",
                  { staticClass: "device-label", attrs: { for: "plant_num" } },
                  [_vm._v("Plant")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.deviceInternal.plant_num.$model,
                      expression: "$v.deviceInternal.plant_num.$model"
                    }
                  ],
                  staticClass: "text-field w-input",
                  attrs: {
                    type: "text",
                    maxlength: "256",
                    "data-name": "plant",
                    placeholder: "Plant Name",
                    id: "plant_num"
                  },
                  domProps: { value: _vm.$v.deviceInternal.plant_num.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.deviceInternal.plant_num,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        !_vm.isNewDevice
          ? _c(
              "div",
              {
                staticClass: "property-item",
                class: {
                  "form-error": _vm.$v.deviceInternal.hours_tracked.$error
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "device-label",
                    attrs: { for: "hours_tracked" }
                  },
                  [_vm._v("Hours Tracked")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.deviceInternal.hours_tracked.$model,
                      expression: "$v.deviceInternal.hours_tracked.$model"
                    }
                  ],
                  staticClass: "text-field w-input",
                  attrs: {
                    type: "text",
                    maxlength: "256",
                    "data-name": "hours_tracked",
                    placeholder: "Hours Tracked",
                    id: "hours_tracked"
                  },
                  domProps: {
                    value: _vm.$v.deviceInternal.hours_tracked.$model
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.deviceInternal.hours_tracked,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                !_vm.$v.deviceInternal.hours_tracked.decimal
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Must be a Number.")
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "property-item" },
          [
            _c("label", { staticClass: "device-label" }, [_vm._v("Billable")]),
            _c(
              "b-form-checkbox",
              {
                attrs: { size: "lg", name: "check-button", switch: "" },
                model: {
                  value: _vm.deviceInternal.billable,
                  callback: function($$v) {
                    _vm.$set(_vm.deviceInternal, "billable", $$v)
                  },
                  expression: "deviceInternal.billable"
                }
              },
              [_vm._v(" Active ")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col s12" }, [
            _vm._v("LastLogin: " + _vm._s(_vm.deviceInternal.lastLogin))
          ])
        ])
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-change-phone",
            centered: "",
            size: "l",
            "hide-footer": "",
            title: "Change Device Mobile Number"
          }
        },
        [
          _c("div", [
            _vm._v(
              "Warning: An incorrect Device Phone number may cause the device to become unreachable!"
            )
          ]),
          _c(
            "div",
            {
              staticClass: "property-item",
              class: { "form-error": _vm.$v.newPhone.$error }
            },
            [
              _c(
                "label",
                { staticClass: "device-label", attrs: { for: "new-phone" } },
                [_vm._v("New Phone Number")]
              ),
              _c("b-form-input", {
                staticClass: "text-field w-input text-input",
                attrs: {
                  type: "text",
                  maxlength: "256",
                  name: "New Mobile Number",
                  "data-name": "Registration",
                  placeholder: "0400000000",
                  id: "new-phone"
                },
                model: {
                  value: _vm.$v.newPhone.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.newPhone, "$model", $$v)
                  },
                  expression: "$v.newPhone.$model"
                }
              }),
              !_vm.$v.newPhone.required
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v("Field is Required")
                  ])
                : _vm._e(),
              !_vm.$v.newPhone.minLength || !_vm.$v.newPhone.maxLength
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v("Must be 10 characters long")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "property-item",
              class: { "form-error": _vm.$v.confirmPhone.$error }
            },
            [
              _c(
                "label",
                {
                  staticClass: "device-label",
                  attrs: { for: "confirm-phone" }
                },
                [_vm._v("Confirm Phone Number")]
              ),
              _c("b-form-input", {
                staticClass: "text-field w-input text-input",
                attrs: {
                  type: "text",
                  maxlength: "256",
                  name: "Confirm Mobile Number",
                  "data-name": "Registration",
                  placeholder: "0400000000",
                  id: "confirm-phone"
                },
                model: {
                  value: _vm.$v.confirmPhone.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.confirmPhone, "$model", $$v)
                  },
                  expression: "$v.confirmPhone.$model"
                }
              }),
              !_vm.$v.confirmPhone.sameAs
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v("Must be the same as New Phone field!")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-change-phone")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.changePhone()
                    }
                  }
                },
                [_vm._v("Change Phone Number")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }