<template>
  <div class="admin-healthcheck-device">
    <b-card>
      <b-card-body :title='"Device Healthcheck - " + deviceInternal.device_name'>
        <b-table-simple v-if="healthStatus" class='no-border'>
          <b-tbody>
            <b-tr>
              <b-th>IMEI</b-th>
              <b-td>{{deviceInternal.device_imei}}</b-td>
            </b-tr>

            <b-tr>
              <b-th>Phone #</b-th>
              <b-td>{{deviceInternal.device_phone}}</b-td>
            </b-tr>

            <b-tr>
              <b-th>Voltage</b-th>
              <b-td>{{healthStatus.data}}V</b-td>
            </b-tr>

            <b-tr>
              <b-th>Last Heartbeat</b-th>
              <b-td>{{ last_seen_heartbeat }}</b-td>
            </b-tr>

            <b-tr>
              <b-th>Last Location:</b-th>
              <b-td>{{ last_seen_location }}</b-td>
            </b-tr>

            <b-tr>
              <b-th>Subscription?</b-th>
              <b-td v-if="healthStatus.subscription">{{healthStatus.subscription.active}}</b-td>
              <b-td v-else>Not found</b-td>
            </b-tr>

<!--            <b-tr>-->
<!--              <b-th>Other?</b-th>-->
<!--              <b-td v-if="healthStatus">{{healthStatus}}</b-td>-->
<!--            </b-tr>-->
          </b-tbody>
        </b-table-simple>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import * as DataProvider from '../helpers/DataProvider'

export default {
  name: 'admin-healthcheck-device',
  components: {},
  props: {
    device: Object
  },
  data: function () {
    return {
      showButtons: true,
      hasPhone: null,
      hasLocation: null,
      deviceCode: '',
      activityTimestamp: '',
      deviceInternal: {
        device_id: 1,
        device_password: 'password',
        device_phone: '042331344',
        alarm_status: false,
        alarm_changed_time_utc: 32142141414,
        overspeed_limit: 0,
        vibration_alarm_active: false,
        odometer_reading: 0,
        has_subscription: true,
        icon: 'Car',
        imei: '351608083567144',
        name: "Matty's car ",
        plant_num: 'matty D',
        registration: '',
        user_id: 1,
        livetrack: false,
        geolimit: false,
        voltage: 12.0,
        hours_tracked: '200'
      },
      healthStatus: {
        voltage: null,
        networks: {},
        last_updated: {}
      }
    }
  },
  computed: {
    last_seen_heartbeat () {
      return moment.utc(this.healthStatus.last_heartbeat).local()
    },
    last_seen_location () {

      if (this.healthStatus.last_updated) {
        return moment.utc(this.healthStatus.last_updated.datetime).local()
      } else {
        return 'No Location Data'
      }
    }
  },
  created () {
    if (this.device) {
      this.deviceInternal = this.device
      this.updateHealthStatus()
    } else {
      this.$bvToast.show('Healthcheck called without device specified!')
    }
  },
  methods: {
    updateHealthStatus: async function () {
      let response = await DataProvider.getHealthStatus(
        this.deviceInternal.device_imei
      )
      console.log('Health Status: ', response)
      this.healthStatus = response.data
      console.log(this.healthStatus)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/variables";

.admin-healthcheck-device {
  color: $text-color-invert;
}

.no-border td, .no-border th{
  border: 0;
  padding: 4pt;
}

th {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 1rem;
  font-weight: 600;
  line-height: 3;
  text-align: right;
}

td {
  font-family: "Open Sans", sans-serif;
  color: $text-color-invert;
  font-size: 1rem;
  font-weight: 600;
  line-height: 3;
}

.command-col {
  display: flex;
  flex-direction: column;
}

.icon-pass {
  font-size: 1.9rem;
  background-color: transparent;
  color: green;
  text-align: center;
}

.icon-fail {
  font-size: 1.9rem;
  background-color: transparent;
  color: red;
  text-align: center;
}

.icon-unknown {
  font-size: 1.9rem;
  background-color: transparent;
  color: red;
  text-align: center;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}
</style>
