<template>
  <div class="admin-command-VL502">
    <b-input-group size="sm" class="mb-3">
      <label class="green-label mt-0">Command Type</label>
      <!--            <b-button @click="$bvModal.show('modal-commands-help')">Commands</b-button>-->
      <b-form-select id="command_type" :options="commandTypes" v-model="commandType"></b-form-select>
    </b-input-group>

    <!--          SMS Command-->
    <b-input-group v-if="commandType==='sms'" size="sm" class="justify-content-around">
      <b-input-group-prepend>
        <b-button @click="$bvModal.show('modal-sms-commands-help')">Templates</b-button>
      </b-input-group-prepend>
      <b-form-input
        v-model="smsCommandText"
        type="text"
        id="filterInput"
        placeholder="Enter Command to Send"
        debounce="500"
        class="text-input"
        @keydown.native="keydownHandler"
      ></b-form-input>
      <b-input-group-append>
        <b-button @click="sendCommand('sms', smsCommandText)" variant="danger">Send via SMS</b-button>
      </b-input-group-append>
    </b-input-group>

    <!--    Control Command-->
    <b-input-group v-if="commandType==='8105'" size="sm">
      <b-select :options="controlCommandOptions" v-model="controlCommand"></b-select>

      <b-button @click="sendControlCommand()" variant="warning">Send Command</b-button>

    </b-input-group>

    <!--          TLV SET Command-->
    <b-input-group v-if="commandType==='8103'" size="sm">
      <b-input-group size="sm" class="tlv-table">
        <label class="green-label mt-0">Select TLV Codes</label>
        <b-input-group size="sm" prepend="Filter:">
          <b-form-input
            v-model="commandFilter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
            debounce="500"
            class="text-input"
          ></b-form-input>
        </b-input-group>
        <div class="tlv-table-inner w-100">
          <b-table striped hover :items="setCodeOptions" :fields="commandFields" responsive
                   :filter="commandFilter" small>
            <!--Select Row Element-->
            <template v-slot:cell(select)="row">
              <b-checkbox :checked="isSelected(row.item)" @change="toggleSelectCode(row.item)"></b-checkbox>
            </template>
          </b-table>

        </div>
      </b-input-group>
      <div size="sm" class="w-100">
        <label class="green-label mt-0">Selected Codes</label>
        <div class="code-container">
          <div v-for="(hexCode, idx) of selectedCodes" v-bind:key="idx" class="code-item">
            <div class="green-label mt-0">Code - {{ commandCodeData[hexCode].name }}</div>
            <div class="code-item-inner">

              <div v-if="commandCodeData[hexCode].format === 'str'">
                <input type="text" v-model="commandCodeValues[hexCode]">
              </div>
              <div v-if="commandCodeData[hexCode].format === 'int'">
                <input type="number" @input="changeInt($event, hexCode)">
              </div>
              <b-button variant="danger" @click="removeCommandCode(hexCode)">X</b-button>
            </div>
          </div>
        </div>

      </div>
      <b-button @click="sendSetCommand()" variant="warning">Send TLV SET</b-button>

    </b-input-group>

    <!--          TLV QUERY Command-->
    <div class="flex-column" v-if="commandType==='8106'">
      <div class="flex-row w-100">
        <div size="sm" class="tlv-table flex-grow-1">
          <label class="green-label mt-0 mb-2">Select Parameter Codes</label>
          <b-input-group size="sm" prepend="Filter:">
            <b-form-input
              v-model="commandFilter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
              debounce="500"
              class="text-input"
            ></b-form-input>
          </b-input-group>
          <div class="tlv-table-inner">
            <b-table striped hover :items="queryCodeOptions" :fields="commandFields" responsive
                     :filter="commandFilter" small>
              <!--Select Row Element-->
              <template v-slot:cell(select)="row">
                <b-checkbox :checked="isSelected(row.item)" @change="toggleSelectCode(row.item)"></b-checkbox>
              </template>
            </b-table>
          </div>
          <div class="green-divider"></div>
          <div class="mt-2">
            <label>Parameters to Query:</label>
            {{ selectedCodes }}
          </div>
        </div>
      </div>
      <b-button @click="sendQueryCommand()" variant="warning">Send TLV Query</b-button>
    </div>

    <!--          Location Query Commands-->
    <b-input-group v-if="commandType==='3001'" size="sm" class="justify-content-around">
      <b-button @click="sendLocationCommand()" variant="warning">Send Location Query</b-button>
    </b-input-group>

    <!--          DTC Clear Commands-->
    <b-input-group v-if="commandType==='3002'" size="sm" class="justify-content-around">
      <b-button @click="sendClearDTCCommand()" variant="warning">Send Clear DTC</b-button>
    </b-input-group>

    <!--          Factory Reset Commands-->
    <b-input-group v-if="commandType==='3003'" size="sm" class="justify-content-around">
      <b-button @click="sendFactoryReset()" variant="danger">Send Factory Reset</b-button>
    </b-input-group>

    <!--          Custom Command-->
    <b-input-group v-if="commandType==='custom'" size="sm">
      <b-form-textarea
        v-model="jsonCommandText"
        type="text"
        rows="6"
        id="rawJSON"
        placeholder="Enter JSON Data"
        class="text-input"
      ></b-form-textarea>
      <b-input-group-append>
        <b-button @click="sendJSONCommand()" variant="warning">Send</b-button>
      </b-input-group-append>
    </b-input-group>

    <!--          <b-input-group-append>-->
    <!--            <b-button @click="sendCommand('data')" variant="primary">Send via Data</b-button>-->

    <!--            <b-button @click="$bvModal.show('modal-json-command')" variant="warning">Send JSON</b-button>-->
    <!--          </b-input-group-append>-->
    <b-modal
      id="modal-sms-commands-help"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Command Help"
    >
      <device-command-help modal-id="modal-sms-commands-help" :command-list="smsCommandList"
                           @change="selectSMSCommand"></device-command-help>
    </b-modal>
  </div>
  <!--   Command Help Modal Template-->
</template>

<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as encoder from '@/components/helpers/SinocastelEncoder'
import DeviceCommandHelp from '@/components/admin/device_command_types/DeviceCommandHelp'
import * as commandList from '@/components/admin/device_command_types/sino_sms_commands.json'
import moment from 'moment'

export default {
  name: 'admin-command-vl502',
  components: {
    DeviceCommandHelp
  },
  props: {
    device: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      currentCommand: '',
      filter: '',
      deviceInternal: {
        device_id: 1,
        imei: '351608083567144',
        device_type: null
      },
      commandFilter: '',
      commandFields: [
        {
          key: 'select',
          label: '-',
          sortable: false,
          thStyle: {
            width: '80px'
          }

        },
        {
          key: 'id',
          label: '#',
          sortable: true
        },
        {
          key: 'format',
          label: 'Format',
          sortable: true
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true
        }
      ],
      jsonCommandText: '',
      commandType: '8106',
      commandTypes: [
        {
          text: 'SMS Command',
          value: 'sms'
        },
        {
          text: 'Parameter Set',
          value: '8103'
        },
        {
          text: 'Parameter Query',
          value: '8106'
        },
        {
          text: 'Control Command',
          value: '8105'
        },
        // {
        //   text: 'Location Query',
        //   value: '3001'
        // },
        // {
        //   text: 'Clear DTC',
        //   value: '3002'
        // },
        // {
        //   text: 'Factory Reset',
        //   value: '3003'
        // },
        // {
        //   text: 'OBD Command',
        //   value: '300F'
        // },
        {
          text: 'Custom Command',
          value: 'custom'
        }
      ],
      smsCommandText: '*' + this.device.device_imei.slice(-6),
      selectedCodes: [],
      selectRemoveCodes: [], // model for the select box that shows selected codes
      tlvConfig: null,
      tlvWriteableCodes: [],
      tlvSetAttributes: {},
      smsCommandList: commandList.commandList,
      commandCodeData: null,
      commandCodeValues: {},
      controlCommandOptions: [
        {
          text: 'Terminal Off',
          value: '03'
        },
        {
          text: 'Terminal Rest',
          value: '04'
        },
        {
          text: 'Factory Reset (Not Server IP)',
          value: '05'
        },
        {
          text: 'Disable Data Communications',
          value: '06'
        },
        {
          text: 'Disable All Wireless Communications',
          value: '07'
        },
        {
          text: 'OBD MCU Firmware Upgrade via Wifi',
          value: 'a1'
        },
        {
          text: 'Terminal Software Upgrade via Wifi',
          value: 'a2'
        },
        {
          text: 'Read OBD MCU Log (via Data Uplink?)',
          value: 'b1'
        },
        {
          text: 'Read Terminal Software Log (via File Upload)',
          value: 'b2'
        }
      ],
      controlCommand: 'b2'
    }
  },
  async created() {
    if (this.device) {
      this.deviceInternal = this.device
    }
    console.log(await this.$configService.getConfig('concoxCodes'))
    let config = await this.$configService.getConfig('concoxCodes')
    if (!config.hasOwnProperty('concox_commands')) {
      ErrorHelper.displayGeneralErrorToast('Failed to Load Command Codes. Some command helpers will not work!')
    } else {
      this.commandCodeData = config.concox_commands
    }
  },
  methods: {
    getTLVAttributes: function (tlvCode) {
      console.log('code:', tlvCode)
      if (this.tlvConfig[tlvCode.value].extended) {
        console.log('TLV attributes: ', this.tlvConfig[tlvCode.value].attributes)
        return this.tlvConfig[tlvCode.value].attributes
      } else {
        return null
      }
    },
    toggleSelectCode(code) {
      console.log(code)
      if (this.selectedCodes.includes(code.id)) {
        this.removeCommandCode(code.id)
      } else {
        this.addCommandCode(code.id)
      }
    },
    addCommandCode: function (codeId) {
      console.log(codeId)
      this.selectedCodes.push(codeId)
      this.selectedCodes = [...new Set(this.selectedCodes)]
    },
    removeCommandCode: function (codeId) {
      this.selectedCodes = this.selectedCodes.filter(x => x !== codeId)
    },
    isSelected: function (code) {
      return this.selectedCodes.includes(code.id)
    },
    sendQueryCommand: async function () {
      let data = {
        'message_id': '8106',
        'message_data': {
          'parameter_ids': this.selectedCodes
        }
      }
      this.sendCommand('data', data, 'Custom Parameter QUERY')
    },
    sendSetCommand: async function () {
      let data = this.getSetCommandData()
      console.log(data)
      this.sendCommand('data', data, 'Custom Parameter SET')
    },
    getSetCommandData: function () {
      return {
        'message_id': '8103',
        'message_data': {
          'parameters': this.selectedCodes.map((hexCode) => {
            return {
              id: hexCode,
              value: this.commandCodeValues[hexCode]
            }
          }),
        }
      }
    },
    sendControlCommand () {
      let data = {
        'message_id': '8105',
        'message_data': {
          'command_code': this.controlCommand,
        }
      }
      console.log(data)
      this.sendCommand('data', data, `Control Command (${this.controlCommand})`)
    },
    changeInt(event, hexCode) {
      console.log(event.target.value, hexCode)
      this.commandCodeValues[hexCode] = parseInt(event.target.value, 10)
      console.log(this.commandCodeValues[hexCode])
    },
    encodeTLV: function (values, tlvCodeConfig) {
      let valArray = ''
      tlvCodeConfig.attributes.forEach((attribute, idx) => {
        valArray = valArray + encoder.encodeValue(values[idx], attribute)
      })
      return valArray
    },
    sendCommand: async function (sendingMethod, data, commandText) {
      let commandSend = await DataProvider.sendDeviceCommands(this.device.device_imei, data, sendingMethod, commandText)
      if (commandSend.success) {
        console.log('Command Response: ', commandSend)
        this.$bvToast.toast('Command sent to device!',
          {
            title: 'Command Registered',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        this.$emit('change')
      } else {
        ErrorHelper.displayDataErrorToast(commandSend)
      }
    },
    sendJSONCommand: async function () {
      let data
      try {
        console.log(this.jsonCommandText)
        data = JSON.parse(this.jsonCommandText.trim())
      } catch (e) {
        ErrorHelper.displayGeneralErrorToast('Unable to parse JSON!', 'Error Parsing JSON')
        console.log(e)
        return
      }
      await this.sendCommand('data', data, 'Custom JSON Command')
    },
    sendLocationCommand: function () {
      let commandData = {
        device_id: this.deviceInternal.device_imei,
        protocol_id: '3001'
      }
      this.sendCommand('data', commandData, 'Location Query')
    },
    sendClearDTCCommand: function () {
      let commandData = {
        device_id: this.deviceInternal.device_imei,
        protocol_id: '3002'
      }
      this.sendCommand('data', commandData, 'Clear DTC Command')
    },
    sendFactoryReset: async function () {
      let commandData = {
        device_id: this.deviceInternal.device_imei,
        protocol_id: '3003'
      }
      let confirm = await this.$bvModal.msgBoxConfirm('This will send a command to the device instructing it to ' +
        'restore factory settings. Are you sure you want to proceed?', {
        title: 'Confirm Factory Reset',
        okVariant: 'danger',
        centered: true
      })
      if (confirm) {
        this.sendCommand('data', commandData, 'Factory Reset')
      }
    },
    // Monitor keys looking for ENTER to be pressed.
    keydownHandler: function (event) {
      if (event.which === 13) {
        this.sendCommand('data')
      }
    },
    selectSMSCommand: function (smsCommand) {
      this.smsCommandText += smsCommand.command
      this.$bvModal.hide('modal-sms-commands-help')
    }
  },
  computed: {
    queryCodeOptions() {
      if (this.commandCodeData) {
        let c = Object.keys(this.commandCodeData).map((hexCode) => {
          return {
            id: hexCode,
            ...this.commandCodeData[hexCode]
          }
        })
        console.log(c)
        return c
      } else {
        return []
      }
    },
    setCodeOptions() {
      if (this.commandCodeData) {
        let c = Object.keys(this.commandCodeData)
          .filter(hexCode => Object.hasOwn(this.commandCodeData[hexCode], 'pack_format'))
          .map((hexCode) => {
            return {
              id: hexCode,
              ...this.commandCodeData[hexCode]
            }
          })
        console.log(c)
        return c
      } else {
        return []
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.admin-command-VL502 {
  width: 100%;
  color: $text-color-invert;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: $theme-color-background-2;
  border: 1px solid $theme-color-primary-2;
  border-radius: 5px;
}

.command-history {
  background: $default-content-background;
  height: calc(100% - 56px);
  overflow-y: scroll;
  max-height: 60vh;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.tlv-table {
  margin: 1% 1%;
  background: $theme-color-background-1;
}

.w-73 {
  max-width: 73%;
}

.tlv-table-inner {
  width: 100%;
  max-height: 25vh;
  overflow: auto;
}

.tlv-table-inner-small {
  max-height: 15vh;
  overflow: auto;
}

.code-container {
  display: flex;
  flex-direction: column;
}

.code-item {
  display: flex;
  flex-direction: column;
  background: $theme-color-background-1;
  border-radius: 5px;
  margin: 5px 1px;
  padding: 5px;
  border: 1px solid $theme-color-primary-2;
}

.code-item-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
