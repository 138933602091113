/***
 * Encode an Array of booleans into a hex string
 * A.k.a turns a bit flag array into a U8
 * @param val
 * @param opts
 * @returns {string}
 */
export function encodeBitfield (val, opts) {
  let result = val.reduce((acc, x, idx) => { return acc | (x << idx) }, 0)
  return result.toString(16).padStart(2, '0')
}

/***
 * Encode a number into Hex. Assumes that the 'int' is actually a U16
 * Also looks for offsets
 * @param val
 * @param opts
 */
export function encodeInteger (val, opts) {
  val = parseInt(val)

  if (opts.hasOwnProperty('offset')) {
    val += parseInt(opts.offset)
  }
  return changeEndian(val.toString(16).padStart(4, '0'))
}

/***
 * Encode a float into Hex. Assumes that the 'float' is actually a U16
 * Also looks for offsets and factors. If no factor is provided, it assumes 10.
 * @param val
 * @param opts
 */
export function encodeFloat (val, opts) {
  val = parseFloat(val)
  if (opts.hasOwnProperty('offset')) {
    val += parseInt(opts.offset)
  }
  if (opts.hasOwnProperty('factor')) {
    val *= parseFloat(opts.factor)
  } else {
    val *= 10
  }
  val = Math.round(val)
  return changeEndian(val.toString(16).padStart(4, '0'))
}

/***
 * Encode a String to hex, by way of ASCII (or other encoding)
 * @param val
 * @param opts
 * @returns {string}
 */
export function encodeString (val, opts) {
  let result
  if (opts.hasOwnProperty('encoding') && opts.encoding === 'utf-16') {
    // Apparently JS already uses UTF-16 encoding.
    // So grab the char codes by turning this into an array, getting the codes for each position, change the
    // value to a hex code, then convert it back into a string.
    result = val.split('').map((x, idx) => val.charCodeAt(idx).toString('16')).join('')
  } else if (opts.hasOwnProperty('encoding')) {
    result = Buffer.from(val, opts.encoding).toString('hex')
  } else {
    result = Buffer.from(val, 'ascii').toString('hex')
  }
  return result // (changeEndian?) Do we need this for a string?
}

/***
 * Encode an index value
 * @param val
 * @param opts
 */
export function encodeIndex (val, opts) {
  // In THEORY these should already be hex strings. The Sinocastel doco looks to be quoting them in hex so...
  return changeEndian(val.toString(16).padStart(2, '0'))
}

/***
 * Encode a Boolean
 * @param val
 * @param opts
 * @returns {string}
 */
export function encodeBool (val, opts) {
  return (+val).toString(16).padStart(2, '0')
}

/***
 * Encode a Boolean
 * @param val
 * @param opts
 * @returns {string}
 */
export function encodePIDArray (val, opts) {
  return val
}

export const encoders = {
  'bitfield': encodeBitfield,
  'int': encodeInteger,
  'float': encodeFloat,
  'string': encodeString,
  'index': encodeIndex,
  'boolean': encodeBool,
  'pid-type-array': encodePIDArray
}

export function encodeValue (val, opts) {
  if (opts.type in encoders) {
    return encoders[opts.type](val, opts)
  } else {
    throw new Error('Unknown Field Type')
  }
}

/***
 * Change the 'endianess' of a Hex String by dividing it up into 2-character segments and fliping them around.
 * @param hexString
 * @returns {*}
 */
function changeEndian (hexString) {
  return hexString.match(/.{1,2}/g).reverse().join('')
}
