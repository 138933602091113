var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-list" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      !_vm.hideFilter
        ? _c("div", { staticClass: "filter-bar" }, [
            _c(
              "div",
              [
                _c(
                  "b-input-group",
                  { attrs: { size: "sm" } },
                  [
                    _c("b-form-input", {
                      staticClass: "text-input",
                      attrs: {
                        type: "search",
                        id: "filterInput",
                        placeholder: "Type to Search"
                      },
                      on: { keypress: _vm.inputPress },
                      model: {
                        value: _vm.filter,
                        callback: function($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter"
                      }
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        !_vm.hideAdvanced
                          ? _c(
                              "b-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.showAdvanced = !_vm.showAdvanced
                                  }
                                }
                              },
                              [_vm._v("Advanced")]
                            )
                          : _vm._e(),
                        _vm.filterMethod === "remote"
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: { click: _vm.applyFilter }
                              },
                              [_vm._v("Apply")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAdvanced,
                        expression: "showAdvanced"
                      }
                    ]
                  },
                  [
                    _c("search-filters", {
                      attrs: {
                        filters: _vm.filters,
                        "default-settings": _vm.filterDefaults
                      },
                      on: {
                        change: _vm.advFilterChange,
                        "press-enter": _vm.applyFilter
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.hideFilter ? _c("div", { staticClass: "green-divider" }) : _vm._e(),
      _c(
        "b-table",
        {
          ref: "deviceTable",
          attrs: {
            striped: "",
            hover: "",
            items: _vm.deviceList,
            fields: _vm.deviceFields,
            "select-mode": _vm.selectMode,
            selectable: _vm.selectable,
            "selected-variant": "success",
            filter: _vm.filterMethod === "local" ? _vm.filter : undefined,
            filterIncludedFields: _vm.filterOn,
            "current-page": _vm.currentPage,
            "per-page": _vm.perPage,
            "td-class": "admin-device-table-data",
            "th-class": "admin-device-table-header"
          },
          on: {
            "row-selected": _vm.tableSelectEvent,
            filtered: _vm.filterChange
          },
          scopedSlots: _vm._u([
            {
              key: "head(selected)",
              fn: function(row) {
                return undefined
              }
            },
            {
              key: "cell(selected)",
              fn: function(row) {
                return [
                  _c("b-form-checkbox", {
                    on: {
                      change: function($event) {
                        return _vm.selectChange(row.item, $event)
                      }
                    },
                    model: {
                      value: row.item.selected,
                      callback: function($$v) {
                        _vm.$set(row.item, "selected", $$v)
                      },
                      expression: "row.item.selected"
                    }
                  })
                ]
              }
            },
            {
              key: "cell(geolimit_status)",
              fn: function(row) {
                return [
                  row.item.geolimit_status.is_active
                    ? _c("p", [_vm._v("On")])
                    : _vm._e(),
                  !row.item.geolimit_status.is_active
                    ? _c("p", [_vm._v("Off")])
                    : _vm._e()
                ]
              }
            },
            {
              key: "cell(billable)",
              fn: function(row) {
                return [
                  _c("b-form-checkbox", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    attrs: { title: "Count Device in Invoices?" },
                    on: {
                      change: function($event) {
                        return _vm.billableChange(row.item, $event)
                      }
                    },
                    model: {
                      value: row.item.billable,
                      callback: function($$v) {
                        _vm.$set(row.item, "billable", $$v)
                      },
                      expression: "row.item.billable"
                    }
                  })
                ]
              }
            },
            {
              key: "cell(archived)",
              fn: function(row) {
                return [
                  _c("b-form-checkbox", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    attrs: { disabled: "", title: "Is Archived?" },
                    model: {
                      value: row.item.archived,
                      callback: function($$v) {
                        _vm.$set(row.item, "archived", $$v)
                      },
                      expression: "row.item.archived"
                    }
                  })
                ]
              }
            },
            {
              key: "cell(has_fuel_data)",
              fn: function(row) {
                return [
                  row.item.has_fuel_data ? _c("p", [_vm._v("Yes")]) : _vm._e(),
                  !row.item.has_fuel_data ? _c("p", [_vm._v("No")]) : _vm._e()
                ]
              }
            },
            {
              key: "cell(has_subscription)",
              fn: function(row) {
                return [
                  row.item.has_subscription
                    ? _c("p", [_vm._v("Yes")])
                    : _vm._e(),
                  !row.item.has_subscription
                    ? _c("p", [_vm._v("No")])
                    : _vm._e()
                ]
              }
            },
            {
              key: "cell(actions)",
              fn: function(row) {
                return [
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass: "row-icon-button row-action",
                    class: _vm.$config.icons.general.edit,
                    attrs: { title: "Edit Device" },
                    on: {
                      click: function($event) {
                        return _vm.deviceActionEdit(row.item)
                      }
                    }
                  }),
                  row.item.user_id
                    ? _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "row-icon-button row-action",
                        class: _vm.$config.icons.general.unassign,
                        attrs: { title: "Unassign" },
                        on: {
                          click: function($event) {
                            return _vm.deviceActionUnassign(row.item)
                          }
                        }
                      })
                    : _vm._e(),
                  !row.item.user_id
                    ? _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "row-icon-button row-action",
                        class: _vm.$config.icons.general.assign,
                        attrs: { title: "Assign" },
                        on: {
                          click: function($event) {
                            return _vm.deviceActionAssign(row.item)
                          }
                        }
                      })
                    : _vm._e(),
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass: "row-icon-button row-action",
                    class: _vm.$config.icons.device.unhealthy,
                    attrs: { title: "Healthcheck" },
                    on: {
                      click: function($event) {
                        return _vm.deviceActionHealthcheck(row.item)
                      }
                    }
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass: "row-icon-button row-action",
                    class: _vm.$config.icons.nav.fleet,
                    attrs: { title: "Device Page" },
                    on: {
                      click: function($event) {
                        return _vm.deviceActionFleet(row.item)
                      }
                    }
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass: "row-icon-button-danger row-action ml-2",
                    class: _vm.$config.icons.general.remove,
                    attrs: { title: "Delete Device" },
                    on: {
                      click: function($event) {
                        return _vm.deviceActionDelete(row.item)
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "row-details",
              fn: function(row) {
                return [
                  _c(
                    "b-card",
                    [
                      _vm._v(" Device List Details "),
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm" },
                          on: { click: row.toggleDetails }
                        },
                        [_vm._v("Hide Details")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_vm._v("+ ")]
      ),
      _vm.loadingMoreDevices
        ? _c("p", [_vm._v("Loading more devices...")])
        : _vm._e(),
      !_vm.hideFilter ? _c("div", { staticClass: "green-divider" }) : _vm._e(),
      !_vm.hidePagination
        ? _c(
            "div",
            { staticClass: "page-bar" },
            [
              _c("b-form-select", {
                staticClass: "page-select",
                attrs: {
                  id: "perPageSelect",
                  size: "sm",
                  options: _vm.pageOptions
                },
                model: {
                  value: _vm.perPage,
                  callback: function($$v) {
                    _vm.perPage = $$v
                  },
                  expression: "perPage"
                }
              }),
              _c("b-pagination", {
                staticClass: "page-buttons",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  align: "fill",
                  size: "sm"
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-edit-device",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Edit Device",
            "no-close-on-backdrop": ""
          }
        },
        [
          _c("admin-edit-device", {
            attrs: {
              "is-modal": "",
              modal: "modal-edit-device",
              device: _vm.currentDevice
            },
            on: {
              save: function($event) {
                return _vm.onSave()
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-healthcheck-device",
            centered: "",
            size: "m",
            "hide-footer": "",
            title: "Healthcheck Device"
          }
        },
        [
          _c("admin-healthcheck-device", {
            attrs: { device: _vm.currentDevice, "hide-button": "" }
          }),
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.$bvModal.hide("modal-healthcheck-device")
                }
              }
            },
            [_vm._v("Close")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-assign-owner",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Assign Owner"
          }
        },
        [
          _c("admin-user-list", {
            attrs: {
              "is-modal": "",
              modal: "modal-assign-owner",
              "single-select-mode": ""
            },
            on: { change: _vm.onOwnerChange }
          }),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-assign-owner")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.assignOwner()
                    }
                  }
                },
                [_vm._v("Assign Owner")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-alarm-device",
            centered: "",
            size: "m",
            "hide-footer": "",
            title: "Alarm State"
          }
        },
        [
          _vm.currentDevice
            ? _c("div", { staticClass: "row" }, [
                _c("label", { staticClass: "device-label" }, [
                  _vm._v("Alarm Change Timestamp:")
                ]),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.formatTimestamp(
                        _vm.currentDevice.alarm_changed_time_utc
                      )
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.currentDevice
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { size: "lg", name: "check-button", switch: "" },
                      model: {
                        value: _vm.currentDevice.alarm_status,
                        callback: function($$v) {
                          _vm.$set(_vm.currentDevice, "alarm_status", $$v)
                        },
                        expression: "currentDevice.alarm_status"
                      }
                    },
                    [_vm._v(" Alarm State ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.$bvModal.hide("modal-alarm-device")
                }
              }
            },
            [_vm._v("Close")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }