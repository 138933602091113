<template>
  <div class="admin-edit-GV20">
    <div class="properties-container">
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.device_imei.$error }"
      >
        <label for="device_imei" class="device-label">IMEI</label>
        <input
          type="text"
          v-model="$v.deviceInternal.device_imei.$model"
          class="text-field w-input"
          placeholder="IMEI"
          id="device_imei"
        />
        <div
          class="input-error"
          v-if="!$v.deviceInternal.device_imei.required"
        >Field is Required</div>
        <div
          class="input-error"
          v-if="!$v.deviceInternal.device_imei.minLength"
        >Must be at least 15 characters</div>
      </div>
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.device_code.$error }"
        v-if="!isNewDevice"
      >
        <label for="email_address" class="device-label">Device Code</label>
        <input
          type="text"
          v-model="$v.deviceInternal.device_code.$model"
          class="text-field w-input"
          maxlength="256"
          placeholder="Device ID"
          id="email_address"
        />
        <div
          class="input-error"
          v-if="!$v.deviceInternal.device_code.required"
        >Field is Required</div>
        <div
          class="input-error"
          v-if="!$v.deviceInternal.device_code.minLength || !$v.deviceInternal.device_code.maxLength"
        >Must be 8 characters long</div>
      </div>
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.device_password.$error }"
        v-if="!isNewDevice"
      >
        <label for="device_password" class="device-label">Device Password</label>
        <input
          type="text"
          v-model="$v.deviceInternal.device_password.$model"
          class="text-field w-input"
          maxlength="256"
          placeholder="Password"
          id="device_password"
        />
      </div>
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.device_name.$error }"
        v-if="!isNewDevice"
      >
        <label for="device_name" class="device-label">Device Name</label>
        <input
          type="text"
          v-model="$v.deviceInternal.device_name.$model"
          class="text-field w-input"
          maxlength="256"
          placeholder="Device Name"
          id="device_name"
        />
        <div
          class="input-error"
          v-if="!$v.deviceInternal.device_name.required"
        >Field is Required</div>
      </div>
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.device_phone.$error }"
      >
        <label for="phone" class="device-label">Mobile Number</label>
        <div class="flex-row">
          <!--              Existing Devices have a confirmation when changing phone numbers-->
          <b-input-group v-if="!isNewDevice">
            <b-form-input
              type="text"
              disabled
              v-model="deviceInternal.device_phone"
              class="text-field w-input text-input"
              maxlength="256"
              name="Mobile Number"
              data-name="Registration"
              placeholder="0400000000"
              id="phone"
            ></b-form-input>
            <b-input-group-append>
              <b-button>
                <i
                  class="row-icon-button row-action"
                  :class="$config.icons.general.edit"
                  @click="$bvModal.show('modal-change-phone')"
                  v-b-tooltip.hover
                  title="Edit Phone Number"
                ></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <!--              New Devices have a plane input box to enter details in-->
          <div v-if="isNewDevice">
            <b-form-input
              type="text"
              v-model="$v.deviceInternal.device_phone.$model"
              class="text-field w-input text-input"
              maxlength="256"
              name="Mobile Number"
              data-name="Registration"
              placeholder="0400000000"
              id="phone"
            ></b-form-input>
          </div>
          <div
            class="input-error"
            v-if="!$v.deviceInternal.device_phone.required"
          >Field is Required</div>
          <div
            class="input-error"
            v-if="!$v.deviceInternal.device_phone.minLength"
          >Must be at least 10 characters long</div>
        </div>
      </div>
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.odometer_reading.$error }"
        v-if="!isNewDevice"
      >
        <label for="odo_reading" class="device-label">Odometer</label>
        <input
          type="text"
          v-model="$v.deviceInternal.odometer_reading.$model"
          class="text-field w-input"
          maxlength="256"
          data-name="odometer"
          placeholder="000000"
          id="odo_reading"
        />
        <div
          class="input-error"
          v-if="!$v.deviceInternal.odometer_reading.decimal"
        >Must be a Number.</div>
      </div>
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.registration.$error }"
        v-if="!isNewDevice"
      >
        <label for="registration" class="device-label">Registration</label>
        <input
          type="text"
          v-model="$v.deviceInternal.registration.$model"
          class="text-field w-input"
          maxlength="256"
          data-name="odometer"
          placeholder="000000"
          id="registration"
        />
      </div>
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.plant_num.$error }"
        v-if="!isNewDevice"
      >
        <label for="plant_num" class="device-label">Plant</label>
        <input
          type="text"
          v-model="$v.deviceInternal.plant_num.$model"
          class="text-field w-input"
          maxlength="256"
          data-name="plant"
          placeholder="Plant Name"
          id="plant_num"
        />
      </div>
      <div
        class="property-item"
        :class="{ 'form-error': $v.deviceInternal.hours_tracked.$error }"
        v-if="!isNewDevice"
      >
        <label for="hours_tracked" class="device-label">Hours Tracked</label>
        <input
          type="text"
          v-model="$v.deviceInternal.hours_tracked.$model"
          class="text-field w-input"
          maxlength="256"
          data-name="hours_tracked"
          placeholder="Hours Tracked"
          id="hours_tracked"
        />
        <div
          class="input-error"
          v-if="!$v.deviceInternal.hours_tracked.decimal"
        >Must be a Number.</div>
      </div>

      <div class=" property-item">
        <label class="device-label">Billable</label>
        <b-form-checkbox size="lg" name="check-button" switch
                         v-model="deviceInternal.billable"> Active
        </b-form-checkbox>
      </div>
      <div class="row mt-2">
        <div class="col s12">LastLogin: {{deviceInternal.lastLogin}}</div>
      </div>
    </div>
    <!--   Change Phone Number Modal Template-->
    <b-modal
      id="modal-change-phone"
      centered
      class="modal-content"
      size="l"
      hide-footer
      title="Change Device Mobile Number"
    >
      <div>Warning: An incorrect Device Phone number may cause the device to become unreachable!</div>
      <div class="property-item" :class="{ 'form-error': $v.newPhone.$error }">
        <label for="new-phone" class="device-label">New Phone Number</label>
        <b-form-input
          type="text"
          v-model="$v.newPhone.$model"
          class="text-field w-input text-input"
          maxlength="256"
          name="New Mobile Number"
          data-name="Registration"
          placeholder="0400000000"
          id="new-phone"
        ></b-form-input>
        <div class="input-error" v-if="!$v.newPhone.required">Field is Required</div>
        <div
          class="input-error"
          v-if="!$v.newPhone.minLength || !$v.newPhone.maxLength"
        >Must be 10 characters long</div>
      </div>
      <div class="property-item" :class="{ 'form-error': $v.confirmPhone.$error }">
        <label for="confirm-phone" class="device-label">Confirm Phone Number</label>
        <b-form-input
          type="text"
          v-model="$v.confirmPhone.$model"
          class="text-field w-input text-input"
          maxlength="256"
          name="Confirm Mobile Number"
          data-name="Registration"
          placeholder="0400000000"
          id="confirm-phone"
        ></b-form-input>
        <div class="input-error" v-if="!$v.confirmPhone.sameAs">Must be the same as New Phone field!</div>
      </div>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-change-phone')">Cancel</b-button>
        <b-button class="button" @click="changePhone()">Change Phone Number</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'

import {
  required,
  minLength,
  maxLength,
  sameAs,
  decimal
} from 'vuelidate/lib/validators'

export default {
  name: 'admin-edit-GV20',
  components: {},
  props: {
    device: Object
  },
  data: function () {
    return {
      deviceIMEI: '',
      displayDevice: null,
      isNewDevice: true,
      newOwner: null,
      ownerEmail: 'Loading...',
      deviceInternal: {},
      newPhone: '',
      confirmPhone: '',
      dirty: false
    }
  },
  validations: {
    phoneGroup: ['newPhone', 'confirmPhone'],
    newPhone: {
      required,
      minLength: minLength(10)
    },
    confirmPhone: {
      required,
      sameAs: sameAs('newPhone')
    },
    deviceInternal: {
      device_imei: {
        required,
        minLength: minLength(15)
      },
      device_code: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(8)
      },
      device_password: {},
      device_name: {
        required
      },
      odometer_reading: {
        decimal
      },
      registration: {},
      plant_num: {},
      hours_tracked: {
        decimal
      },
      device_phone: {
        required,
        minLength: minLength(10)
      }
    }
  },
  created () {
    if (this.device) {
      // If we have been passed the device prop, then populate based on that.
      this.deviceInternal = { ...this.device } // Take a shallow copy of the device to avoid editing parent's data
      this.deviceIMEI = this.device.device_imei
      this.isNewDevice = false
    } else {
      this.deviceInternal = {
        device_imei: '',
        device_code: '',
        selected: false,
        device_phone: '',
        device_name: '',
        odometer_reading: 0,
        registration: 0,
        plant_num: '',
        billable: true,
        admin: false,
        overspeed_limit: 0,
        vibration_alarm_active: false
      }
    }
  },
  methods: {
    changePhone: async function () {
      // Trigger Vuelidate validations for our phoneGroup of fields.
      this.$v.phoneGroup.$touch()
      console.log(this.$v.phoneGroup.$anyError)
      if (this.$v.phoneGroup.$anyError) {
        return
      }
      console.log('Change Phone Number to ', this.newPhone)
      let response = await DataProvider.adminUpdateDevicePhone(
        this.deviceIMEI,
        this.newPhone
      )
      if (response.success) {
        AlertHelper.successToast(
          'Device phone Number Updated!',
          'Changes Saved.'
        )
        this.deviceInternal.device_phone = this.newPhone
        this.device.device_phone = this.newPhone // Update the source data to keep the parent in sync
        this.$bvModal.hide('modal-change-phone')
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    saveChanges: async function () {
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast(
          'Some Fields Contain Invalid Data. Please fix them.',
          'Invalid Fields'
        )
        return false
      }
      let res
      if (this.isNewDevice) {
        this.deviceInternal.icon = 'Car'
        this.deviceInternal.yes_really_set_device_phone = true
        if (this.deviceInternal.device_phone === '') {
          ErrorHelper.displayGeneralWarningToast(
            'Device Mobile Number is Required',
            'Mobile Number Required'
          )
          return false
        }

        if (this.deviceInternal.device_code === '') {
          this.deviceInternal.device_code =
            'A' +
            this.deviceInternal.device_imei.substring(
              this.deviceInternal.device_imei.length - 7,
              this.deviceInternal.device_imei.length
            )
        }
        let updateFields = {
          device_imei: this.deviceInternal.device_imei,
          device_code: this.deviceInternal.device_code,
          device_phone: this.deviceInternal.device_phone,
          device_type: 'device_benway_gv20'
        }

        res = await DataProvider.adminCreateDevice(updateFields)
      } else {
        let updates = { ...this.deviceInternal }
        delete updates.device_type
        res = await DataProvider.adminUpdateDevice(
          this.deviceIMEI,
          updates
        )
      }
      if (res.success) {
        this.deviceInternal = res.data
        this.deviceIMEI = res.data.device_imei

        if (this.isNewDevice) {
          this.$emit('createDevice', this.deviceIMEI)
        }
        this.isNewDevice = false
        this.$bvToast.toast('The changes you made have been saved!', {
          title: 'Changes Saved',
          variant: 'success',
          toaster: 'b-toaster-top-center'
        })
        this.$v.$reset()
        this.$emit('save')
        return true
      } else {
        ErrorHelper.displayDataErrorToast(res)
        return false
      }
    }
  },
  watch: {
    deviceInternal: {
      handler (newVal, oldVal) {
        this.dirty = this.$v.$anyDirty
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.admin-edit-GV20 {
  color: $text-color-invert;
}

.device-label {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.device-code {
  color: $text-color-invert;
  margin-left: 5%;
}

.properties-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.property-item {
  width: 18rem;
}

.toolbar {
  display: flex;
  flex-direction: row;
}

.icon-button,
.icon-button-warning,
.icon-button-danger {
  font-size: 1.9rem;
}

.row-action {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.command-container {
  height: 60vh;
  position: relative;
}

.form-control:disabled {
  background: #7d7d7d;
  color: $text-color-invert;
}
</style>
