var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-command-213LU" },
    [
      _c(
        "b-input-group",
        { staticClass: "mb-3", attrs: { size: "sm" } },
        [
          _c("label", { staticClass: "green-label mt-0" }, [
            _vm._v("Command Type")
          ]),
          _c("b-form-select", {
            attrs: { id: "command_type", options: _vm.commandTypes },
            model: {
              value: _vm.commandType,
              callback: function($$v) {
                _vm.commandType = $$v
              },
              expression: "commandType"
            }
          })
        ],
        1
      ),
      _vm.commandType === "sms"
        ? _c(
            "b-input-group",
            { staticClass: "justify-content-around", attrs: { size: "sm" } },
            [
              _c(
                "b-input-group-prepend",
                [
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show("modal-sms-commands-help")
                        }
                      }
                    },
                    [_vm._v("Templates")]
                  )
                ],
                1
              ),
              _c("b-form-input", {
                staticClass: "text-input",
                attrs: {
                  type: "text",
                  id: "filterInput",
                  placeholder: "Enter Command to Send",
                  debounce: "500"
                },
                nativeOn: {
                  keydown: function($event) {
                    return _vm.keydownHandler.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.smsCommandText,
                  callback: function($$v) {
                    _vm.smsCommandText = $$v
                  },
                  expression: "smsCommandText"
                }
              }),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.sendCommand("sms", _vm.smsCommandText)
                        }
                      }
                    },
                    [_vm._v("Send via SMS")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.commandType === "2001"
        ? _c(
            "b-input-group",
            { attrs: { size: "sm" } },
            [
              _c(
                "b-input-group",
                { staticClass: "tlv-table", attrs: { size: "sm" } },
                [
                  _c("label", { staticClass: "green-label mt-0" }, [
                    _vm._v("Select TLV Codes")
                  ]),
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm", prepend: "Filter:" } },
                    [
                      _c("b-form-input", {
                        staticClass: "text-input",
                        attrs: {
                          type: "search",
                          id: "filterInput",
                          placeholder: "Type to Search",
                          debounce: "500"
                        },
                        model: {
                          value: _vm.tlvFilter,
                          callback: function($$v) {
                            _vm.tlvFilter = $$v
                          },
                          expression: "tlvFilter"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tlv-table-inner w-100" },
                    [
                      _c("b-table", {
                        attrs: {
                          striped: "",
                          hover: "",
                          items: _vm.tlvWriteableCodes,
                          fields: _vm.tlvFields,
                          responsive: "",
                          filter: _vm.tlvFilter,
                          small: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(select)",
                              fn: function(row) {
                                return [
                                  !_vm.isSelected(row.item)
                                    ? _c(
                                        "b-button",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addTLVCode(row.item)
                                            }
                                          }
                                        },
                                        [_vm._v("+")]
                                      )
                                    : _vm._e(),
                                  _vm.isSelected(row.item)
                                    ? _c("i", {
                                        staticClass: "fa fa-check icon-button"
                                      })
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3307422787
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "w-100", attrs: { size: "sm" } }, [
                _c("label", { staticClass: "green-label mt-0" }, [
                  _vm._v("Selected Codes")
                ]),
                _c(
                  "div",
                  { staticClass: "code-container" },
                  _vm._l(_vm.tlvCodesSelected, function(tlvCode, idx) {
                    return _c("div", { key: idx, staticClass: "code-item" }, [
                      _c("div", { staticClass: "green-label mt-0" }, [
                        _vm._v("Code - " + _vm._s(tlvCode.text))
                      ]),
                      _c(
                        "div",
                        { staticClass: "code-item-inner" },
                        [
                          _vm._l(_vm.getTLVAttributes(tlvCode), function(
                            tlvAttribute,
                            attributeIdx
                          ) {
                            return _c(
                              "div",
                              { key: attributeIdx, staticClass: "w-100" },
                              [
                                tlvAttribute.type === "boolean" ||
                                tlvAttribute.type === "bool"
                                  ? _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          size: "sm",
                                          name: "check-button",
                                          switch: ""
                                        },
                                        model: {
                                          value:
                                            _vm.tlvSetAttributes[tlvCode.value][
                                              attributeIdx
                                            ],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tlvSetAttributes[
                                                tlvCode.value
                                              ],
                                              attributeIdx,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tlvSetAttributes[tlvCode.value][attributeIdx]"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(tlvAttribute.name) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                tlvAttribute.type === "bitfield"
                                  ? _c(
                                      "div",
                                      { attrs: { title: "Bit Field" } },
                                      _vm._l(tlvAttribute.field_names, function(
                                        field_name,
                                        bitfieldIdx
                                      ) {
                                        return _c(
                                          "b-form-checkbox",
                                          {
                                            key: bitfieldIdx,
                                            attrs: {
                                              size: "sm",
                                              name: "check-button"
                                            },
                                            model: {
                                              value:
                                                _vm.tlvSetAttributes[
                                                  tlvCode.value
                                                ][attributeIdx][bitfieldIdx],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tlvSetAttributes[
                                                    tlvCode.value
                                                  ][attributeIdx],
                                                  bitfieldIdx,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tlvSetAttributes[tlvCode.value][attributeIdx][bitfieldIdx]"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(field_name) + " "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                                tlvAttribute.type === "int" ||
                                tlvAttribute.type === "float"
                                  ? _c(
                                      "b-input-group",
                                      { attrs: { append: tlvAttribute.unit } },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "green-label mt-0" },
                                          [_vm._v(_vm._s(tlvAttribute.name))]
                                        ),
                                        _c(
                                          "b-input",
                                          {
                                            attrs: {
                                              size: "sm",
                                              name: "check-button",
                                              type: "number",
                                              max: tlvAttribute.max,
                                              min: tlvAttribute.min
                                            },
                                            model: {
                                              value:
                                                _vm.tlvSetAttributes[
                                                  tlvCode.value
                                                ][attributeIdx],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tlvSetAttributes[
                                                    tlvCode.value
                                                  ],
                                                  attributeIdx,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tlvSetAttributes[tlvCode.value][attributeIdx]"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(tlvAttribute.name) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                tlvAttribute.type === "string"
                                  ? _c(
                                      "b-input-group",
                                      { attrs: { append: tlvAttribute.unit } },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "green-label mt-0" },
                                          [_vm._v(_vm._s(tlvAttribute.name))]
                                        ),
                                        _c(
                                          "b-input",
                                          {
                                            attrs: {
                                              size: "sm",
                                              name: "check-button",
                                              type: "text",
                                              max: tlvAttribute.max,
                                              min: tlvAttribute.min
                                            },
                                            model: {
                                              value:
                                                _vm.tlvSetAttributes[
                                                  tlvCode.value
                                                ][attributeIdx],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tlvSetAttributes[
                                                    tlvCode.value
                                                  ],
                                                  attributeIdx,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tlvSetAttributes[tlvCode.value][attributeIdx]"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(tlvAttribute.name) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                tlvAttribute.type === "index"
                                  ? _c(
                                      "b-input-group",
                                      { attrs: { append: tlvAttribute.unit } },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "green-label mt-0" },
                                          [_vm._v(_vm._s(tlvAttribute.name))]
                                        ),
                                        _c(
                                          "b-select",
                                          {
                                            attrs: {
                                              size: "sm",
                                              name: "check-button",
                                              options: tlvAttribute.options
                                            },
                                            model: {
                                              value:
                                                _vm.tlvSetAttributes[
                                                  tlvCode.value
                                                ][attributeIdx],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tlvSetAttributes[
                                                    tlvCode.value
                                                  ],
                                                  attributeIdx,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tlvSetAttributes[tlvCode.value][attributeIdx]"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(tlvAttribute.name) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                tlvAttribute.type === "pid-type-array"
                                  ? _c(
                                      "b-input-group",
                                      { staticClass: "flex-grow-1" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "green-label mt-0" },
                                          [_vm._v(_vm._s(tlvAttribute.name))]
                                        ),
                                        _c("b-input", {
                                          staticClass: "flex-grow-1",
                                          attrs: {
                                            size: "lg",
                                            name: "check-button",
                                            type: "text"
                                          },
                                          model: {
                                            value:
                                              _vm.tlvSetAttributes[
                                                tlvCode.value
                                              ][attributeIdx],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.tlvSetAttributes[
                                                  tlvCode.value
                                                ],
                                                attributeIdx,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tlvSetAttributes[tlvCode.value][attributeIdx]"
                                          }
                                        }),
                                        _c("div", [
                                          _vm._v("PID Hex Codes, No spaces")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.removeTLVCode(tlvCode)
                                }
                              }
                            },
                            [_vm._v("X")]
                          )
                        ],
                        2
                      )
                    ])
                  }),
                  0
                )
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.sendTLVSetCommand()
                    }
                  }
                },
                [_vm._v("Send TLV SET")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.commandType === "2002"
        ? _c(
            "b-input-group",
            { attrs: { size: "sm" } },
            [
              _c(
                "b-input-group",
                { staticClass: "tlv-table w-73", attrs: { size: "sm" } },
                [
                  _c("label", { staticClass: "green-label mt-0 mb-2" }, [
                    _vm._v("Select TLV Codes")
                  ]),
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm", prepend: "Filter:" } },
                    [
                      _c("b-form-input", {
                        staticClass: "text-input",
                        attrs: {
                          type: "search",
                          id: "filterInput",
                          placeholder: "Type to Search",
                          debounce: "500"
                        },
                        model: {
                          value: _vm.tlvFilter,
                          callback: function($$v) {
                            _vm.tlvFilter = $$v
                          },
                          expression: "tlvFilter"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tlv-table-inner" },
                    [
                      _c("b-table", {
                        attrs: {
                          striped: "",
                          hover: "",
                          items: _vm.tlvCodeOptions,
                          fields: _vm.tlvFields,
                          responsive: "",
                          filter: _vm.tlvFilter,
                          small: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(select)",
                              fn: function(row) {
                                return [
                                  !_vm.isSelected(row.item)
                                    ? _c(
                                        "b-button",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addTLVCode(row.item)
                                            }
                                          }
                                        },
                                        [_vm._v("+")]
                                      )
                                    : _vm._e(),
                                  _vm.isSelected(row.item)
                                    ? _c("i", {
                                        staticClass: "fa fa-check icon-button"
                                      })
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3307422787
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-input-group",
                { staticClass: "w-25", attrs: { size: "sm" } },
                [
                  _c(
                    "div",
                    [
                      _c("label", { staticClass: "green-label mt-0" }, [
                        _vm._v("Selected Codes")
                      ]),
                      _c("b-form-select", {
                        attrs: {
                          "select-size": 8,
                          options: _vm.tlvCodesSelected,
                          multiple: ""
                        },
                        model: {
                          value: _vm.tlvCodeRemove,
                          callback: function($$v) {
                            _vm.tlvCodeRemove = $$v
                          },
                          expression: "tlvCodeRemove"
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "danger",
                            disabled: !_vm.tlvCodeRemove
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeTLVCode()
                            }
                          }
                        },
                        [_vm._v("Remove")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.sendTLVQueryCommand()
                    }
                  }
                },
                [_vm._v("Send TLV Query")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.commandType === "3001"
        ? _c(
            "b-input-group",
            { staticClass: "justify-content-around", attrs: { size: "sm" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.sendLocationCommand()
                    }
                  }
                },
                [_vm._v("Send Location Query")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.commandType === "3002"
        ? _c(
            "b-input-group",
            { staticClass: "justify-content-around", attrs: { size: "sm" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.sendClearDTCCommand()
                    }
                  }
                },
                [_vm._v("Send Clear DTC")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.commandType === "3003"
        ? _c(
            "b-input-group",
            { staticClass: "justify-content-around", attrs: { size: "sm" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.sendFactoryReset()
                    }
                  }
                },
                [_vm._v("Send Factory Reset")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.commandType === "custom"
        ? _c(
            "b-input-group",
            { attrs: { size: "sm" } },
            [
              _c("b-form-textarea", {
                staticClass: "text-input",
                attrs: {
                  type: "text",
                  rows: "6",
                  id: "rawJSON",
                  placeholder: "Enter JSON Data"
                },
                model: {
                  value: _vm.jsonCommandText,
                  callback: function($$v) {
                    _vm.jsonCommandText = $$v
                  },
                  expression: "jsonCommandText"
                }
              }),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "warning" },
                      on: {
                        click: function($event) {
                          return _vm.sendJSONCommand()
                        }
                      }
                    },
                    [_vm._v("Send")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-sms-commands-help",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Command Help"
          }
        },
        [
          _c("device-command-help", {
            attrs: {
              "modal-id": "modal-sms-commands-help",
              "command-list": _vm.smsCommandList
            },
            on: { change: _vm.selectSMSCommand }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }