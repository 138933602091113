var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-show-recent-trips" },
    [
      _c(
        "b-form-group",
        { attrs: { id: "input-start-date", label: "Start Date:" } },
        [
          _c("b-form-datepicker", {
            staticClass: "text-input date-picker z-2",
            attrs: { type: "date", size: "sm", placeholder: "YYYY/MM/DD" },
            model: {
              value: _vm.startDate,
              callback: function($$v) {
                _vm.startDate = $$v
              },
              expression: "startDate"
            }
          }),
          _c(
            "div",
            { staticClass: "row flex-row-reverse m-0" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("modal-defrag-trips")
                    }
                  }
                },
                [_vm._v("Defrag")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("modal-dedupe-trips")
                    }
                  }
                },
                [_vm._v("Dedupe")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("modal-cont-check-trips")
                    }
                  }
                },
                [_vm._v("Cont Check")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.deviceTrips && _vm.deviceTrips["error"] > 0
        ? _c("div", [_c("h3", [_vm._v("No recent device trips")])])
        : _vm._e(),
      _vm.deviceTrips && _vm.deviceTrips.trips
        ? _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              items: _vm.deviceTrips.trips,
              fields: _vm.tripFields
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(start_location)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " (" +
                          _vm._s(data.item.start_latitude.toFixed(6)) +
                          ", " +
                          _vm._s(data.item.start_longitude.toFixed(6)) +
                          ") "
                      )
                    ]
                  }
                },
                {
                  key: "cell(end_location)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " (" +
                          _vm._s(data.item.end_latitude.toFixed(6)) +
                          ", " +
                          _vm._s(data.item.end_longitude.toFixed(6)) +
                          ") "
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              4517574
            )
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-defrag-trips",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Defrag Trips"
          }
        },
        [
          _c("div", [
            _vm._v(" Defragment Trips for this device. This will "),
            _c("strong", [_vm._v("rebuild")]),
            _vm._v(
              " trips during the specified period by breaking them down to raw locations and rebuilding them entirely. This process can take a while if a long period is selected, so the operation may time out, but the server should still complete the work in the background. "
            )
          ]),
          _c(
            "label",
            { staticClass: "green-label", attrs: { for: "startDate" } },
            [_vm._v("Start:")]
          ),
          _c(
            "div",
            { staticClass: "row z-2" },
            [
              _c("b-timepicker", {
                staticClass: "z-3 text-input date-picker w-25",
                model: {
                  value: _vm.periodStartTime,
                  callback: function($$v) {
                    _vm.periodStartTime = $$v
                  },
                  expression: "periodStartTime"
                }
              }),
              _c("b-datepicker", {
                staticClass: "z-2 text-input date-picker w-75",
                attrs: { id: "startDate" },
                model: {
                  value: _vm.periodStartDate,
                  callback: function($$v) {
                    _vm.periodStartDate = $$v
                  },
                  expression: "periodStartDate"
                }
              })
            ],
            1
          ),
          _c(
            "label",
            { staticClass: "green-label", attrs: { for: "endDate" } },
            [_vm._v("End Date:")]
          ),
          _c(
            "div",
            { staticClass: "row z-1" },
            [
              _c("b-timepicker", {
                staticClass: "z-2 text-input date-picker w-25",
                model: {
                  value: _vm.periodEndTime,
                  callback: function($$v) {
                    _vm.periodEndTime = $$v
                  },
                  expression: "periodEndTime"
                }
              }),
              _c("b-datepicker", {
                staticClass: "z-1 text-input date-picker w-75",
                attrs: { id: "endDate", "no-flip": "" },
                model: {
                  value: _vm.periodEndDate,
                  callback: function($$v) {
                    _vm.periodEndDate = $$v
                  },
                  expression: "periodEndDate"
                }
              })
            ],
            1
          ),
          _vm.defragResultMessage
            ? _c("div", [
                _c("label", { staticClass: "green-label" }, [
                  _vm._v("Result:")
                ]),
                _vm._v(" " + _vm._s(_vm.defragResultMessage) + " ")
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-defrag-trips")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  attrs: { variant: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.defragTrips()
                    }
                  }
                },
                [_vm._v("Defrag Trips")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-dedupe-trips",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Deduplicate Trips"
          }
        },
        [
          _c("div", [
            _vm._v(
              " Check trips within the given period and remove any which appear to be duplicates. This only looks at start/end times of trips and does not dive into the individual locations. Duplicate trips will be *deleted* and their data is not generally recoverable, so use this "
            ),
            _c("strong", [_vm._v("only")]),
            _vm._v(" if an error has resulted in duplicated trip data. ")
          ]),
          _c(
            "label",
            { staticClass: "green-label", attrs: { for: "startDate" } },
            [_vm._v("Start:")]
          ),
          _c(
            "div",
            { staticClass: "row z-2" },
            [
              _c("b-timepicker", {
                staticClass: "z-3 text-input date-picker w-25",
                model: {
                  value: _vm.periodStartTime,
                  callback: function($$v) {
                    _vm.periodStartTime = $$v
                  },
                  expression: "periodStartTime"
                }
              }),
              _c("b-datepicker", {
                staticClass: "z-2 text-input date-picker w-75",
                attrs: { id: "startDate" },
                model: {
                  value: _vm.periodStartDate,
                  callback: function($$v) {
                    _vm.periodStartDate = $$v
                  },
                  expression: "periodStartDate"
                }
              })
            ],
            1
          ),
          _c(
            "label",
            { staticClass: "green-label", attrs: { for: "endDate" } },
            [_vm._v("End Date:")]
          ),
          _c(
            "div",
            { staticClass: "row z-1" },
            [
              _c("b-timepicker", {
                staticClass: "z-2 text-input date-picker w-25",
                model: {
                  value: _vm.periodEndTime,
                  callback: function($$v) {
                    _vm.periodEndTime = $$v
                  },
                  expression: "periodEndTime"
                }
              }),
              _c("b-datepicker", {
                staticClass: "z-1 text-input date-picker w-75",
                attrs: { id: "endDate", "no-flip": "" },
                model: {
                  value: _vm.periodEndDate,
                  callback: function($$v) {
                    _vm.periodEndDate = $$v
                  },
                  expression: "periodEndDate"
                }
              })
            ],
            1
          ),
          _vm.dedupeResultMessage
            ? _c("div", [
                _c("label", { staticClass: "green-label" }, [
                  _vm._v("Result:")
                ]),
                _vm._v(" " + _vm._s(_vm.dedupeResultMessage) + " ")
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-dedupe-trips")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  attrs: { variant: "warning" },
                  on: {
                    click: function($event) {
                      return _vm.dedupeTrips()
                    }
                  }
                },
                [_vm._v("Deduplicate Trips")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-cont-check-trips",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Check Trips"
          }
        },
        [
          _c("div", [
            _vm._v(
              " Perform a consistency check on trips within the below period. This process is non-destructive and will simply check the contents of the trips to determine if there are any obvious inconsistencies e.g overlapping start/end times. This process can be slow and may time out. "
            )
          ]),
          _c(
            "label",
            { staticClass: "green-label", attrs: { for: "startDate" } },
            [_vm._v("Start:")]
          ),
          _c(
            "div",
            { staticClass: "row z-2" },
            [
              _c("b-timepicker", {
                staticClass: "z-3 text-input date-picker w-25",
                model: {
                  value: _vm.periodStartTime,
                  callback: function($$v) {
                    _vm.periodStartTime = $$v
                  },
                  expression: "periodStartTime"
                }
              }),
              _c("b-datepicker", {
                staticClass: "z-2 text-input date-picker w-75",
                attrs: { id: "startDate" },
                model: {
                  value: _vm.periodStartDate,
                  callback: function($$v) {
                    _vm.periodStartDate = $$v
                  },
                  expression: "periodStartDate"
                }
              })
            ],
            1
          ),
          _c(
            "label",
            { staticClass: "green-label", attrs: { for: "endDate" } },
            [_vm._v("End Date:")]
          ),
          _c(
            "div",
            { staticClass: "row z-1" },
            [
              _c("b-timepicker", {
                staticClass: "z-2 text-input date-picker w-25",
                model: {
                  value: _vm.periodEndTime,
                  callback: function($$v) {
                    _vm.periodEndTime = $$v
                  },
                  expression: "periodEndTime"
                }
              }),
              _c("b-datepicker", {
                staticClass: "z-1 text-input date-picker w-75",
                attrs: { id: "endDate", "no-flip": "" },
                model: {
                  value: _vm.periodEndDate,
                  callback: function($$v) {
                    _vm.periodEndDate = $$v
                  },
                  expression: "periodEndDate"
                }
              })
            ],
            1
          ),
          _vm.checkResultMessage
            ? _c("div", [
                _c("label", { staticClass: "green-label" }, [
                  _vm._v("Result:")
                ]),
                _vm._v(" " + _vm._s(_vm.checkResultMessage) + " ")
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-cont-check-trips")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.checkTrips()
                    }
                  }
                },
                [_vm._v("Check Trips")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }