<template>
  <div class="admin-show-recent-trips">
    <b-form-group id='input-start-date' label="Start Date:">
      <b-form-datepicker type="date" class="text-input date-picker z-2" v-model="startDate"
                         size="sm" placeholder="YYYY/MM/DD">
      </b-form-datepicker>
      <div class="row flex-row-reverse m-0">
        <b-button class="button" @click="$bvModal.show('modal-defrag-trips')">Defrag</b-button>
        <b-button class="button" @click="$bvModal.show('modal-dedupe-trips')">Dedupe</b-button>
        <b-button class="button" @click="$bvModal.show('modal-cont-check-trips')">Cont Check</b-button>
      </div>
    </b-form-group>
        <div v-if="deviceTrips && deviceTrips['error'] > 0">
          <h3>No recent device trips</h3>
        </div>

        <b-table striped hover v-if="deviceTrips && deviceTrips.trips" :items="deviceTrips.trips" :fields="tripFields">
          <template v-slot:cell(start_location)="data">
            ({{ data.item.start_latitude.toFixed(6) }}, {{ data.item.start_longitude.toFixed(6)}})
          </template>
          <template v-slot:cell(end_location)="data">
            ({{ data.item.end_latitude.toFixed(6) }}, {{ data.item.end_longitude.toFixed(6)}})
          </template>
        </b-table>

<!--    Defrag Trips-->
    <b-modal
      id="modal-defrag-trips"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Defrag Trips"
    >
      <div>
        Defragment Trips for this device. This will <strong>rebuild</strong> trips during the specified period by
        breaking them down to raw locations and rebuilding them entirely. This process can take a while if a long period
        is selected, so the operation may time out, but the server should still complete the work in the background.
      </div>
      <label for="startDate" class="green-label">Start:</label>
      <div class="row z-2">
        <b-timepicker v-model="periodStartTime" class="z-3 text-input date-picker w-25"></b-timepicker>
        <b-datepicker id="startDate" v-model="periodStartDate" class="z-2 text-input date-picker w-75"></b-datepicker>
      </div>

      <label for="endDate" class="green-label">End Date:</label>
      <div class="row z-1">
        <b-timepicker v-model="periodEndTime" class="z-2 text-input date-picker w-25"></b-timepicker>
        <b-datepicker id="endDate" v-model="periodEndDate" class="z-1 text-input date-picker w-75" no-flip></b-datepicker>
      </div>
      <div v-if="defragResultMessage">
        <label class="green-label">Result:</label>
        {{defragResultMessage}}
      </div>
      <div class="row mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-defrag-trips')">Cancel</b-button>
        <b-button class="button" @click="defragTrips()" variant="warning">Defrag Trips</b-button>
      </div>
    </b-modal>
    <!--    Dedupe Trips-->
    <b-modal
      id="modal-dedupe-trips"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Deduplicate Trips"
    >
      <div>
        Check trips within the given period and remove any which appear to be duplicates. This only looks at start/end
        times of trips and does not dive into the individual locations. Duplicate trips will be *deleted* and their
        data is not generally recoverable, so use this <strong>only</strong> if an error has resulted in duplicated trip
        data.
      </div>
      <label for="startDate" class="green-label">Start:</label>
      <div class="row z-2">
        <b-timepicker v-model="periodStartTime" class="z-3 text-input date-picker w-25"></b-timepicker>
        <b-datepicker id="startDate" v-model="periodStartDate" class="z-2 text-input date-picker w-75"></b-datepicker>
      </div>

      <label for="endDate" class="green-label">End Date:</label>
      <div class="row z-1">
        <b-timepicker v-model="periodEndTime" class="z-2 text-input date-picker w-25"></b-timepicker>
        <b-datepicker id="endDate" v-model="periodEndDate" class="z-1 text-input date-picker w-75" no-flip></b-datepicker>
      </div>
      <div v-if="dedupeResultMessage">
        <label class="green-label">Result:</label>
        {{dedupeResultMessage}}
      </div>
      <div class="row mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-dedupe-trips')">Cancel</b-button>
        <b-button class="button" @click="dedupeTrips()" variant="warning">Deduplicate Trips</b-button>
      </div>
    </b-modal>
    <!--    Cont Check Trips-->
    <b-modal
      id="modal-cont-check-trips"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Check Trips"
    >
      <div>
        Perform a consistency check on trips within the below period. This process is non-destructive and will simply
        check the contents of the trips to determine if there are any obvious inconsistencies e.g overlapping start/end
        times. This process can be slow and may time out.
      </div>
      <label for="startDate" class="green-label">Start:</label>
      <div class="row z-2">
        <b-timepicker v-model="periodStartTime" class="z-3 text-input date-picker w-25"></b-timepicker>
        <b-datepicker id="startDate" v-model="periodStartDate" class="z-2 text-input date-picker w-75"></b-datepicker>
      </div>

      <label for="endDate" class="green-label">End Date:</label>
      <div class="row z-1">
        <b-timepicker v-model="periodEndTime" class="z-2 text-input date-picker w-25"></b-timepicker>
        <b-datepicker id="endDate" v-model="periodEndDate" class="z-1 text-input date-picker w-75" no-flip></b-datepicker>
      </div>
      <div v-if="checkResultMessage">
        <label class="green-label">Result:</label>
        {{checkResultMessage}}
      </div>
      <div class="row mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-cont-check-trips')">Cancel</b-button>
        <b-button class="button" @click="checkTrips()">Check Trips</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import moment from 'moment'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'

export default {
  name: 'admin-show-recent-trips',
  components: {},
  props: {
    device: null
  },
  data: function () {
    return {
      deviceTrips: null,
      startDate: moment().local().toISOString(),
      tripFields: [
        'id',
        'device_imei',
        {
          key: 'start_time',
          formatter: (value, key, item) => {
            return moment(value * 1000).local()
          }
        },
        'state',
        'start_location',
        {
          key: 'end_time',
          formatter: (value, key, item) => {
            return moment(value * 1000).local()
          }
        },
        'end_location',
        {
          key: 'n_locations',
          label: '# Locations'
        },
        'kms_travelled',
        'maximum_speed',
        {
          key: 'time_taken',
          formatter: (value, key, item) => {
            let hours = parseInt(value / 60 / 60)
            let minutes = parseInt((value - (hours * 60 * 60)) / 60)

            let hoursString = ''
            if (hours > 0) {
              hoursString = `${hours}h`
            }
            let minutesString = ''
            if (minutes > 0) {
              minutesString = `${minutes}m`
            }
            return hoursString + minutesString
          }
        }
      ],
      periodStartDate: moment().local().format('YYYY-MM-DD'),
      periodStartTime: moment().local().format('HH:00'),
      periodEndDate: moment().local().subtract(1, 'day').format('YYYY-MM-DD'),
      periodEndTime: moment().local().format('HH:00'),
      defragResultMessage: null,
      dedupeResultMessage: null,
      checkResultMessage: null
    }
  },
  computed: {
    endDate () {
      return moment(this.startDate).add(1, 'days')
    }
  },
  created () {
    this.startDate = moment().subtract(1, 'days').toISOString()
    if (this.device) {
      this.deviceInternal = this.device
      this.getRecentTrips()
    } else {
      this.$bvToast.show('Cannot show recent trips without device specified!')
    }
    console.log('Start', this.startDate)
  },
  methods: {
    getRecentTrips: async function () {
      let response = await DataProvider.getDeviceTrips(
        this.deviceInternal.device_imei, this.startDate, this.endDate
      )
      console.log('Device trips: ', response)
      if (response.error) {
        this.deviceTrips = response.error
      } else {
        this.deviceTrips = response.data
      }
      console.log(this.deviceTrips)
    },
    getStartTimestamp: function () {
      return moment(this.periodStartDate + ' ' + this.periodStartTime).unix()
    },
    getEndTimestamp: function () {
      return moment(this.periodEndDate + ' ' + this.periodEndTime).unix()
    },
    defragTrips: async function () {
      let confirm = await this.$bvModal.msgBoxConfirm('Really DEFRAG Trips?', {
        centered: true,
        okVariant: 'warning'
      })
      if (confirm) {
        this.defragResultMessage = 'Waiting for Server...'
        let resp = await DataProvider.adminDefragTrips(
          this.device.device_imei,
          this.getStartTimestamp(),
          this.getEndTimestamp())
        if (resp.success) {
          // complete': True, 'orginal_trip_count': n_original_trips, 'new_trip_count': n_new_trips
          this.defragResultMessage = `Success? ${resp.data.complete} Original Trips: ${resp.data.orginal_trip_count}
          New Trips: ${resp.data.new_trip_count}.`
        } else {
          this.defragResultMessage = 'Error! Operation failed.'
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
    },
    dedupeTrips: async function () {
      let confirm = await this.$bvModal.msgBoxConfirm('Really DEDUPE Trips?', {
        centered: true,
        okVariant: 'warning'
      })
      if (confirm) {
        this.dedupeResultMessage = 'Waiting for Server...'
        let resp = await DataProvider.adminDedupeTrips(
          this.device.device_imei,
          this.getStartTimestamp(),
          this.getEndTimestamp())
        if (resp.success) {
          console.log('Result: ', resp.data)
          // {'complete': True, 'trips_removed': result}
          this.dedupeResultMessage = `Success? ${resp.data.complete}
          Deuplicate Trips Removed: ${resp.data.trips_removed}`
        } else {
          this.dedupeResultMessage = 'Error! Operation failed.'
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
    },
    checkTrips: async function () {
      this.checkResultMessage = 'Waiting for Server...'
      let resp = await DataProvider.adminCheckTrips(
        this.device.device_imei,
        this.getStartTimestamp(),
        this.getEndTimestamp())
      if (resp.success) {
        // {'is_consistent': result, 'trip_count': len(trips)}
        this.checkResultMessage = `Are Trips Consistent? ${resp.data.is_consistent} Trip Count: ${resp.data.trip_count}`
      } else {
        this.checkResultMessage = 'Error! Operation failed.'
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  watch: {
    startDate: function (value) {
      this.getRecentTrips()
    }
  }
}
</script>

<style scoped lang="scss">
  .admin-show-recent-trips {
    width: 100%;
    overflow: auto;
  }

  .z-3 {
    z-index: 3;
  }
  .z-2 {
    z-index: 2;
  }

</style>
