var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-edit-213LU" }, [
    _c(
      "div",
      { staticClass: "section-container" },
      [
        _c("div", { staticClass: "green-label" }, [
          _vm._v("Device Configuration")
        ]),
        _c("div", { staticClass: "green-divider" }),
        _c("b-textarea", {
          staticClass: "w-100",
          attrs: { rows: "6" },
          model: {
            value: _vm.configText,
            callback: function($$v) {
              _vm.configText = $$v
            },
            expression: "configText"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "section-container" },
      [
        _c("div", { staticClass: "green-label" }, [_vm._v("Device State")]),
        _c("div", { staticClass: "green-divider" }),
        _c("b-textarea", {
          staticClass: "w-100",
          attrs: { rows: "6" },
          model: {
            value: _vm.stateText,
            callback: function($$v) {
              _vm.stateText = $$v
            },
            expression: "stateText"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }