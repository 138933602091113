<template>
  <div class="admin-edit-213LU">
    <div class="section-container">
      <div class="flex-row justify-content-between">
        <h4>Device Configuration</h4>
        <div class="icon-button">
          <i class="fa fa-refresh" v-b-tooltip="'Refresh State'" @click="refreshState"></i>
        </div>
      </div>
      <div class="green-divider"></div>
      <b-tabs>
        <b-tab title="Parameters">
          <b-table small class="p-0" :items="deviceParameters" responsive :fields="configFields">
            <template v-slot:cell(value)="row">
              <b-input-group class="flex-row" v-if="row.item.format==='bitmask'">
                <input class="flex-grow-1" :value="bitMaskSummary(row.value)" disabled>
                <b-input-group-append>
                  <button class="slim-controls" @click="showBitmask(row.item)">...</button>
                </b-input-group-append>
              </b-input-group>
              <div class="flex-row"  v-else>
                <input class="flex-grow-1" v-model="row.value" disabled>
              </div>

            </template>
            <template v-slot:cell(from_device)="row">
              <b-checkbox :checked="row.value" disabled></b-checkbox>
            </template>
          </b-table>

        </b-tab>
        <b-tab title="JSON">
          <b-textarea class="w-100" v-model="configText" rows="6"></b-textarea>
        </b-tab>
      </b-tabs>
    </div>
    <div class="section-container">
      <div class="flex-row">
        <h4>Device Configuration</h4>
      </div>
      <b-textarea class="w-100" v-model="stateText" rows="6"></b-textarea>
    </div>

    <b-modal
      id="modal-show-bitmask"
      centered
      class="modal-content"
      size="xl"
      title="Bitmask Detail"
    >
      <b-table :items="selectedBitmaskItems">
        <template v-slot:cell(value)="row">
          <b-checkbox :checked="row.value" disabled></b-checkbox>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'


export default {
  name: 'admin-device-state-VL502',
  components: {},
  props: {
    device: Object
  },
  data: function () {
    return {
      deviceIMEI: '',
      deviceInternal: {},
      deviceState: null,
      configText: null,
      stateText: null,
      commandCodeData: null,
      configFields: [
        {
          key: 'parameter_hex',
          label: 'ID',
          sortable: true
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'value',
          label: 'Value',
          sortable: true
        },
        {
          key: 'last_update',
          label: 'Updated',
          sortable: true
        },
        {
          key: 'from_device',
          label: 'From Device',
          sortable: true
        }
      ],
      selectedBitmask: null
    }
  },
  async created () {
    if (this.device) {
      // If we have been passed the device prop, then populate based on that.
      this.deviceInternal = { ...this.device } // Take a shallow copy of the device to avoid editing parent's data
      this.deviceIMEI = this.device.device_imei
      this.isNewDevice = false

      console.log(await this.$configService.getConfig('concoxCodes'))
      let config = await this.$configService.getConfig('concoxCodes')
      if (!config.hasOwnProperty('concox_commands')) {
        ErrorHelper.displayGeneralErrorToast('Failed to Load Command Codes. Some command helpers will not work!')
      } else {
        this.commandCodeData = config.concox_commands
      }

      await this.refreshState()
      console.log('Device State Data: ', this.deviceState)
    }
  },
  methods: {
    async refreshState() {
      this.deviceState = await this.loadDeviceState(this.deviceIMEI)
      this.updateConfig(this.deviceState.configuration_data)
      this.updateState(this.deviceState.state_data)
    },
    loadDeviceState: async function (deviceImei) {
      let resp = await DataProvider.getDeviceProperty(deviceImei, 'device_state')
      if (resp.success) {
        return resp.data.device_state
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    updateConfig: function (data) {
      this.configText = JSON.stringify(data, null, 2)
    },
    updateState: function (data) {
      this.stateText = JSON.stringify(data, null, 2)
    },
    bitMaskSummary(value) {
      if (value) {
        return JSON.stringify(value)
      } else {
        return ''
      }
    },
    showBitmask(item) {
      this.selectedBitmask = item
      this.$bvModal.show('modal-show-bitmask')
    }
  },
  computed: {
    commandCodeDataByName () {
      if (this.commandCodeData) {
        let codesByName = {}
        Object.keys(this.commandCodeData).forEach((hexCode) => {
          let configItem = this.commandCodeData[hexCode]
          codesByName[configItem.name] = {
            id: hexCode,
            ...configItem
          }
        })
        return codesByName
      } else {
        return null
      }
    },
    deviceParameters () {
      if (this.commandCodeDataByName && this.deviceState && this.deviceState.configuration_data) {
        let filteredItems = Object.keys(this.deviceState.configuration_data)
          .filter(parameterName => Object.hasOwn(this.deviceState.configuration_data[parameterName], 'value') &&
            Object.hasOwn(this.deviceState.configuration_data[parameterName], 'from_device'))

        return filteredItems.map((parameterName) => {
          let paramData = this.deviceState.configuration_data[parameterName]
          let paramConfig = this.commandCodeDataByName[parameterName]
          return {
            name: parameterName,
            value: paramData.value,
            from_device: paramData.from_device,
            last_update: dt.timestampToLocalDateTime(paramData.timestamp),
            format: paramConfig ? paramConfig.format : null,
            parameter_hex: paramConfig ? paramConfig.id : null,
          }
        })
      } else {
        return []
      }
    },
    selectedBitmaskItems () {
      if (this.selectedBitmask) {
        console.log(this.selectedBitmask)
        return Object.keys(this.selectedBitmask.value).map((itemName) => {
          return {
            flagName: itemName,
            value: !!this.selectedBitmask.value[itemName]
          }
        })
      } else {
        return []
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.admin-edit-213LU {
  color: $text-color-invert;
}




</style>
