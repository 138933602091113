<template>
  <div class="admin-edit-device">
    <b-tabs lazy>
      <!--      General Tab-->
      <b-tab title="General">
        <label for="device_type" class="device-label">Device Type</label>
        <b-form-select :options="devicetypes" v-model="deviceInternal.device_type" @change="changeDeviceType"></b-form-select>
        <div class="green-divider"></div>
        <component v-if="editComponent" :is="editComponent" :device="device" ref="editComponent"
                   :deviceType="deviceInternal.device_type"
                   @createDevice="onCreateDevice"></component>
      </b-tab>
      <!--      SIM Tabs-->
      <b-tab title="SIM">
        <div v-if="!deviceInternal.sim">
          <b-button @click="$bvModal.show('modal-assign-sim-card')" >Assign SIM</b-button>
          No SIM Assigned
        </div>
        <div v-if="deviceInternal.sim">
          <b-button @click="unassignSIM" >Unassign SIM</b-button>
          <admin-edit-sim-card  modal="false" @save="$emit('save')"
                                :sim-card="deviceInternal.sim"></admin-edit-sim-card>
        </div>
      </b-tab>
      <!--      Permissions Tab-->
      <b-tab title="Permissions">
        <div class="ml-3">
          <label for="owner" class="device-label">Owner</label>
          <div class="row">
            <div class="property-item">
              <input
                type="text"
                v-model="ownerEmail"
                class="text-field"
                disabled
                maxlength="256"
                data-name="owner"
                placeholder="Owner ID"
                id="owner"
              />
            </div>
            <i
              class="icon-button"
              :class="$config.icons.general.assign"
              @click="showAssignDevice"
              v-b-tooltip.hover
              title="Assign Device"
            ></i>
            <i
              class="icon-button-warning"
              :class="$config.icons.general.unassign"
              @click="clickUnAssignDevice"
              v-b-tooltip.hover
              title="Unassign Device"
            ></i>
          </div>
          <div class="green-divider"></div>
          <div class="row justify-content-between">
            <label class="device-label">Delegates</label>
            <!--            <i class="icon-button mr-3" :class="$config.icons.user.add" @click="showAddDelegate" v-b-tooltip.hover title="Add More Delegates"></i>-->
          </div>
          <!--          <b-table striped hover :items="sharedWith" :fields="shareFields" select-mode="single" selected-variant="success"-->
          <!--                   td-class="admin-device-table-data" th-class="admin-device-table-header">-->
          <!--            &lt;!&ndash;Select All Header Element&ndash;&gt;-->
          <!--            <template v-slot:head(selected)="row">-->
          <!--              <b-form-checkbox v-model="selectAll" v-on:change="toggleSelectAll"></b-form-checkbox>-->
          <!--            </template>-->
          <!--            &lt;!&ndash;Select Checkbox Row Element&ndash;&gt;-->
          <!--            <template v-slot:cell(selected)="row">-->
          <!--              <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>-->
          <!--            </template>-->
          <!--            &lt;!&ndash;Subscribed Write Permission Element&ndash;&gt;-->
          <!--            <template v-slot:cell(permission_level)="row">-->
          <!--              <b-select v-model="row.item.permission_level" :options="permissionOptions" v-on:change="permissionChange(row.item, $event)"-->
          <!--                               v-b-tooltip.hover title="User Permission Level"></b-select>-->
          <!--            </template>-->
          <!--            &lt;!&ndash;Actions Row Element&ndash;&gt;-->
          <!--            <template v-slot:cell(actions)="row">-->
          <!--              <i class="row-icon-button-danger row-action ml-2" :class="$config.icons.general.remove" @click="shareActionDelete(row.item)" v-b-tooltip.hover title="Remove Access"></i>-->
          <!--            </template>-->
          <!--          </b-table>-->
        </div>
      </b-tab>
      <!--      Healthcheck Tab-->
      <b-tab title="Healthcheck" v-if="!isNewDevice">
        <admin-healthcheck-device :device="deviceInternal"></admin-healthcheck-device>
      </b-tab>
      <!--      Device Trips Tab-->
      <b-tab title="Device Trips" v-if="!isNewDevice">
        <admin-show-recent-trips :device="deviceInternal" class="command-container"></admin-show-recent-trips>
      </b-tab>
      <!--      Device State Tab-->
      <b-tab title="Device State" v-if="!isNewDevice && this.stateComponent">
        <component v-if="this.stateComponent" :is="this.stateComponent" :device="device" ref="stateComponent"
                   ></component>
      </b-tab>
      <!--      Commands Tab-->
      <b-tab title="Command History" v-if="!isNewDevice">
        <div class="command-container">
          <admin-command-device :device="deviceInternal"></admin-command-device>
        </div>
      </b-tab>
      <b-tab title="Auditlog">
        <AuditLog v-if="!isNewDevice" related_type="device" :related_id="deviceInternal.device_id"></AuditLog>
      </b-tab>
    </b-tabs>
    <div class="footer mt-2">
      <b-button class="button" @click="clickClose()">Close</b-button>
      <b-button class="button" @click="saveChanges()">Save Changes</b-button>
    </div>

    <!--   Share with Users Modal Template-->
    <b-modal
      id="modal-add-delegates"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Share Device With"
    >
      <admin-user-list is-modal modal="delegates" multi-select-mode @change="onDelegateChange"></admin-user-list>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-add-delegates')">Cancel</b-button>
        <b-button class="button" @click="addDelegates()">Add Delegates</b-button>
      </div>
    </b-modal>

    <!--   Assign Owner Modal Template-->
    <b-modal
      id="modal-assign-owner"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Assign Owner"
    >
      <admin-user-list
        is-modal
        modal="modal-assign-owner"
        single-select-mode
        @change="onOwnerChange"
      ></admin-user-list>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-assign-owner')">Cancel</b-button>
        <b-button class="button" @click="assignOwner()">Assign Owner</b-button>
      </div>
    </b-modal>

    <!--   Assign SIM Modal Template-->
    <b-modal
      id="modal-assign-sim-card"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Assign Owner"
    >
      <admin-sim-card-list selectable select-mode="single" @row-selected="selectSIMRow"></admin-sim-card-list>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-assign-sim-card')">Cancel</b-button>
        <b-button class="button" :disabled="!selectedSIM" @click="assignSIM()">Assign SIM</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AdminUserList from './AdminUserList'
import AdminHealthcheckDevice from './AdminHealthcheckDevice'
import AdminShowRecentTrips from './AdminShowRecentTrips'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'
import AdminCommandDevice from './AdminCommandDevice'
import AdminEditGenericVehicle from '@/components/admin/device_types/AdminEditGenericVehicle'
import AdminEditGV20 from '@/components/admin/device_types/AdminEditGV20'
import AdminEdit213LU from '@/components/admin/device_types/AdminEdit213LU'
import AdminEditGenericObject from '@/components/admin/device_types/AdminEditGenericObject'
import AdminDeviceState213LU from '@/components/admin/device_states/AdminDeviceState213LU'
import {adminUpdateDeviceType} from '../helpers/DataProvider'
import AdminEditSimCard from '@/components/admin/AdminEditSIMCard'
import AdminSimCardList from '@/components/admin/AdminSIMCardList'
import AuditLog from '@/components/shared/AuditLogList.vue'
import AdminDeviceStateVL502 from '@/components/admin/device_states/AdminDeviceStateVL502.vue'
import AdminDeviceStateVL512 from '@/components/admin/device_states/AdminDeviceStateVL512.vue'

export default {
  name: 'admin-edit-device',
  components: {
    AuditLog,
    AdminEditGenericVehicle,
    AdminEditGenericObject,
    AdminEditGV20,
    AdminEdit213LU,
    AdminCommandDevice,
    AdminUserList,
    AdminHealthcheckDevice,
    AdminShowRecentTrips,
    AdminDeviceState213LU,
    AdminSimCardList,
    AdminEditSimCard
    // AdminSimCardList: () => import ('./AdminSimCardList'),
    // AdminEditSimCard: () => import ('./AdminEditSimCard')
  },
  props: {
    isModal: Boolean,
    modal: String,
    device: Object
  },
  data: function () {
    return {
      devicetypes: [],
      deviceIMEI: '',
      editComponents: {
        'generic_object_tracker': AdminEditGenericObject,
        'device_benway_gv20': AdminEditGV20,
        'generic_vehicle_tracker': AdminEditGenericVehicle,
        'device_sinocastel_213lu': AdminEdit213LU,
        'device_kingwo_nt06e': AdminEditGenericObject,
        'device_concox_vl502': AdminEditGenericVehicle,
        'device_concox_vl512': AdminEditGenericVehicle
      },
      stateComponents: {
        'device_sinocastel_213lu': AdminDeviceState213LU,
        'device_kingwo_nt06e': AdminDeviceState213LU,
        'device_concox_vl502': AdminDeviceStateVL502,
        'device_concox_vl512': AdminDeviceStateVL512
      },
      editComponent: null,
      stateComponent: null,
      showButtons: true,
      displayDevice: null,
      isNewDevice: true,
      selectAll: false,
      selectedDevices: null,
      newOwner: null,
      ownerEmail: 'Loading...',
      deviceInternal: {
        device_type: null
      },
      newPhone: '',
      confirmPhone: '',
      permissionOptions: ['Read Only', 'Read/Write'],
      shareFields: [
        {
          key: 'selected',
          label: '-'
        },
        { key: 'email', label: 'Email Address', sortable: true },
        { key: 'permission_level', label: 'Permissions', sortable: false },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      sharedWith: [
        {
          id: 0,
          selected: false,
          email: 'bob@hotmail.com',
          permission_level: 'Read Only'
        }
      ],
      newDelegates: [],
      editableComponents: [], // Components to check for dirty and saveChanges,
      selectedSIM: null
    }
  },
  created () {
    this.devicetypes = this.$deviceService.getDeviceTypes()
    if (this.device) {
      // If we have been passed the device prop, then populate based on that.
      this.deviceInternal = { ...this.device } // Take a shallow copy of the device to avoid editing parent's data
      this.deviceIMEI = this.device.device_imei
      this.editComponent = this.getEditComponent(this.device.device_type)
      this.stateComponent = this.getStateComponent(this.device.device_type)
      this.isNewDevice = false
      if (this.deviceInternal.user_id) {
        this.loadOwner()
      } else {
        this.ownerEmail = 'Unassigned'
      }
      // this.loadPermissions()
    } else {
      this.deviceInternal = {
        device_imei: '',
        device_code: '',
        selected: false,
        device_phone: '',
        device_name: '',
        odometer_reading: 0,
        registration: 0,
        plant_num: '',
        billable: true,
        admin: false,
        overspeed_limit: 0,
        vibration_alarm_active: false
      }
    }

    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
  },
  mounted () {
    // Add in any editable components here. They will be checked for 'dirty' when the modal is closed and
    // 'saveChanges()' will be called on them when the button is pressed.
    this.$nextTick(() => {
      this.updateEditComponents()
    })
  },
  methods: {
    getEditComponent: function (deviceType) {
      if (this.editComponents.hasOwnProperty(deviceType)) {
        return this.editComponents[deviceType]
      } else {
        throw new Error(`No Device Edit Component for Device Type ${deviceType}.`)
      }
    },
    getStateComponent: function (deviceType) {
      if (this.stateComponents.hasOwnProperty(deviceType)) {
        return this.stateComponents[deviceType]
      }
    },
    updateEditComponents: function () {
      this.editableComponents = [
      ]
      // Check that the ref exists first. It won't on new items.
      if (this.$refs.editComponent) {
        this.editableComponents.push(this.$refs.editComponent)
      }
    },
    // Load Device Details from server
    loadDevice: async function (deviceImei) {
      let response = await DataProvider.getDeviceByIMEI(deviceImei)
      if (response.success) {
        // this.deviceInternal = response.data
        return response.data
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    // Load the details of the device owner
    loadOwner: async function () {
      let userData = await DataProvider.adminGetUser(this.deviceInternal.user_id)
      if (userData.success) {
        this.ownerEmail = userData.data.email
      } else {
        ErrorHelper.displayDataErrorToast(userData)
        this.ownerEmail = 'Error Loading'
      }
    },
    loadPermissions: async function () {
      // TODO - Finish implementing this when the API supports Admins viewing/editing device shares
      let response = await DataProvider.getShareList()
      console.log('Permissions: ', response)
      if (response.success) {
        this.shareWith = response.data.permission_list
      }
    },
    changePhone: async function () {
      // Trigger Vuelidate validations for our phoneGroup of fields.
      this.$v.phoneGroup.$touch()
      console.log(this.$v.phoneGroup.$anyError)
      if (this.$v.phoneGroup.$anyError) {
        return
      }
      console.log('Change Phone Number to ', this.newPhone)
      let response = await DataProvider.adminUpdateDevicePhone(
        this.deviceIMEI,
        this.newPhone
      )
      if (response.success) {
        AlertHelper.successToast(
          'Device phone Number Updated!',
          'Changes Saved.'
        )
        this.deviceInternal.device_phone = this.newPhone
        this.device.device_phone = this.newPhone // Update the source data to keep the parent in sync
        this.$bvModal.hide('modal-change-phone')
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    saveChanges: async function () {
      console.log(this.editableComponents)
      let dirtyComps = this.editableComponents.filter(x => x.dirty)
      // If any of the required save actions fail (return false) don't close the window.
      let result = []
      for (let comp of dirtyComps) {
        result.push(await comp.saveChanges())
      }
      if (result.every(x => x)) {
        // this.$bvModal.hide(this.modal)
        this.$emit('save')
      }
    },
    toggleSelectAll: function (event) {
      if (event) {
        this.sharedWith.forEach(x => {
          x.selected = true
        })
      } else {
        this.sharedWith.forEach(x => {
          x.selected = false
        })
      }
    },
    // Called when user clicks the icon to show the assignment modal.
    showAssignDevice: function () {
      this.$bvModal.show('modal-assign-owner')
    },
    clickUnAssignDevice: async function () {
      console.log('device: ', this.deviceInternal)
      if (!this.deviceInternal.user_id) {
        ErrorHelper.displayGeneralWarningToast(
          'The device is not assigned, so you cannot unassign it.',
          'Device Not Assigned'
        )
        return
      }
      let resp = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to UNASSIGN this device, sending it back to the pool of unassign devices?',
        {
          title: 'Confirm Unassign',
          okVariant: 'warning',
          centered: true
        }
      )
      if (resp) {
        let result = await DataProvider.adminUnAssignDevice(
          this.deviceInternal.device_imei
        )
        if (result.success) {
          AlertHelper.successToast(
            'Device Successfully Unassigned.',
            'Device Unassigned'
          )
          this.deviceInternal.user_id = null
          this.ownerEmail = 'Unassigned'
          this.$emit('save')
        } else {
          ErrorHelper.displayDataErrorToast(result)
        }
      }
    },
    showAddDelegate: function () {
      this.$bvModal.show('modal-add-delegates')
    },
    shareActionDelete: async function (item) {
      let resp = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to DELETE ${item.email} access to this device?`,
        {
          title: 'Confirm Removal',
          okVariant: 'danger',
          centered: true
        }
      )
      if (resp) {
        console.log('Do Remove Access')
      }
      // TODO - Stub Method
    },
    clickClose: async function () {
      console.log(this.editableComponents)
      let dirty = this.editableComponents.some(x => x.dirty)
      if (dirty) {
        let res = await this.$bvModal.msgBoxConfirm(
          'You have unsaved changes, are you sure you want to close this window?',
          {
            title: 'Confirm Discard Changes',
            okVariant: 'warning',
            centered: true
          }
        )
        if (!res) {
          return
        }
      }
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    permissionChange: function (row, state) {
      // TODO - Stub Method
      console.log('Changed Permission State')
    },
    addDelegates: function () {
      if (this.newDelegates.length === 0) {
        ErrorHelper.displayGeneralErrorToast(
          'You have not selected any delegates!',
          'No Selection Made'
        )
      } else {
        console.log('Assign Delegates: ', this.newDelegates)
        this.$bvModal.hide('modal-add-delegates')
        // TODO - Stub Method
      }
    },
    // Fired when the user list indicates the delegate selection has changed.
    onDelegateChange: function (newDelegates) {
      console.log('Delegate change, ', newDelegates)
      this.newDelegates = newDelegates
    },
    // Fired when the user presses 'Assign Owner' from the Owner Selection Modal
    assignOwner: async function () {
      if (!this.newOwner) {
        ErrorHelper.displayGeneralErrorToast(
          'You need to select a new owner.',
          'No Selection Made'
        )
      } else {
        let result = await DataProvider.adminAssignDevice(
          this.deviceInternal.device_imei,
          this.newOwner.email
        )
        if (result.success) {
          this.$bvModal.hide('modal-assign-owner')
          AlertHelper.successToast('Device Owner Updated', 'Owner Updated')
          this.ownerEmail = this.newOwner.email
          this.deviceInternal.user_id = this.newOwner.id
          this.$emit('save')
        } else {
          ErrorHelper.displayDataErrorToast(result)
        }
      }
    },
    // Event Fired when a new selection is made via the user list.
    onOwnerChange: function (newOwner) {
      // Update our temp variable until the user decides to save.
      this.newOwner = newOwner[0]
    },
    onCreateDevice: async function (deviceImei) {
      // Reload the device after the first save
      let device = await this.loadDevice(deviceImei)
      this.deviceInternal = device
      this.deviceIMEI = deviceImei
      this.isNewDevice = false
    },
    changeDeviceType: async function (newVal) {
      if (!this.isNewDevice) {
        let confirm = await this.$bvModal.msgBoxConfirm('Are you SURE you want to change the device type? This ' +
          'will purge the device\' and configuration data from the system and may result in strange behavior if the ' +
          'device has already been active on the system.')
        if (confirm) {
          let resp = await DataProvider.adminUpdateDeviceType(this.deviceInternal.device_imei, newVal)
          if (resp.success) {
            this.$emit('save')
            this.$bvModal.hide(this.modal)
          }
        }
      } else {
        this.editComponent = this.getEditComponent(newVal)
        this.stateComponent = this.getStateComponent(newVal)
        this.$nextTick(() => {
          // Wait for the next tick so that .$refs is popuplated.
          this.updateEditComponents()
          console.log('Editable components', this.editableComponents)
        })
      }
    },
    selectSIMRow: function (selection) {
      console.log('selection: ', selection)
      this.selectedSIM = selection[0]
    },
    assignSIM: async function () {
      let res = await DataProvider.adminUpdateSIM(this.selectedSIM.sim_id, null, this.deviceInternal.device_imei)
      if (res.success) {
        AlertHelper.successToast('SIM Card Assigned to device!', 'Changes Saved!')
        this.deviceInternal.sim = this.selectedSIM
        this.$bvModal.hide('modal-assign-sim-card')
        this.$emit('save')
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    unassignSIM: async function () {
      let confirm = await this.$bvModal.msgBoxConfirm('This SIM is associated with a device, ' +
        'are you sure you want to Unassign it?', {
        title: 'Confirm Unassignment',
        okVariant: 'warning',
        centered: true
      })
      if (confirm) {
        let resp = await DataProvider.adminUnassignSIM(this.deviceInternal.sim.sim_id)
        if (!resp.success) {
          ErrorHelper.displayDataErrorToast(resp)
        } else {
          AlertHelper.successToast('Unassigned SIM Card', 'Changes Saved!')
          this.$emit('save')
          this.$bvModal.hide(this.modal)
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../variables";

.admin-edit-device {
  color: $text-color-invert;
}

.device-label {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.device-code {
  color: $text-color-invert;
  margin-left: 5%;
}

.properties-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.property-item {
  width: 18rem;
}

.toolbar {
  display: flex;
  flex-direction: row;
}

.icon-button,
.icon-button-warning,
.icon-button-danger {
  font-size: 1.9rem;
}

.row-action {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.command-container {
  position: relative;
}

.form-control:disabled {
  background: #7d7d7d;
  color: $text-color-invert;
}
</style>
