<template>
  <div class="admin-command-device">
    <b-card class="">
      <b-card-body class="flex-column flex-nowrap align-content-stretch">
        <div class="flex-column">Device Command Console - {{ deviceInternal.device_name }}</div>
        <component v-if="deviceControlComponent" :is="deviceControlComponent" :device="deviceInternal"
                   @change="updateCommandHistory"></component>
        <!--        <b-input-group size="sm">-->
        <!--          <b-input-group-prepend>-->
        <!--            <b-button @click="$bvModal.show('modal-commands-help')">Commands</b-button>-->
        <!--          </b-input-group-prepend>-->
        <!--          <b-form-input-->
        <!--            v-model="currentCommand"-->
        <!--            type="text"-->
        <!--            id="filterInput"-->
        <!--            placeholder="Enter Command to Send"-->
        <!--            debounce="500"-->
        <!--            class="text-input"-->
        <!--            @keydown.native="keydownHandler"-->
        <!--          ></b-form-input>-->
        <!--          <b-input-group-append>-->
        <!--            <b-button @click="sendCommand('data')" variant="primary">Send via Data</b-button>-->
        <!--            <b-button @click="sendCommand('sms')" variant="danger">Send via SMS</b-button>-->
        <!--            <b-button @click="$bvModal.show('modal-json-command')" variant="warning">Send JSON</b-button>-->
        <!--          </b-input-group-append>-->
        <!--        </b-input-group>-->
        <div class="w-100">
          <div class="command-history-title flex-row">
            <div>
              Command History
            </div>

            <div>
              <b-spinner v-show="loading" text="Loading"></b-spinner>
            </div>

            <div class="flex-row">
              <div class="icon-button" @click="$bvModal.show('modal-command-queue')"
                   v-b-tooltip="'Show Command Queue'"
              >
                Q
              </div>
              <div class="icon-button" :class="{'active': autoScroll}" @click="autoScroll=!autoScroll"
                   v-b-tooltip="'Toggle Auto-Scroll'"
              >
                <i class="fa fa-angle-double-up"></i>
              </div>
              <div class="icon-button" :class="{'active': refreshTimer}" @click="toggleTimer()"
                   v-b-tooltip="'Toggle Auto-Refresh'"
              >
                <i class="fa fa-history"></i>
              </div>
              <div class="icon-button" @click="updateCommandHistory()"
                   v-b-tooltip="'Refresh'"
              >
                <i class="fa fa-refresh"></i>
              </div>
            </div>
          </div>
          <div class="command-history" ref="commandHistory" id="command-history">
            <div v-for="item of deviceHistory" v-bind:key="item.id"
                 :class="{'selected': selectedCommand !==null && item.id ===selectedCommand.id}">
              <div class="command-item">
                <i :class="$config.icons.messaging.to_device" class="ml-1"
                   v-if="item.direction === 'to_device'"
                   v-b-tooltip title="TO Device"
                ></i>
                <i
                  :class="$config.icons.messaging.from_device" class="ml-1"
                  v-if="item.direction === 'from_device'"
                  v-b-tooltip title="FROM Device"
                ></i>
                <i
                  :class="$config.icons.messaging.data" class="ml-1"
                  v-if="item.method === 'data'"
                  v-b-tooltip title="via DATA"
                ></i>
                <i
                  :class="$config.icons.messaging.sms" class="ml-1"
                  v-if="item.method === 'sms' || item.method === 'Twilio'"
                  v-b-tooltip title="via SMS"
                ></i>

                <i
                  :class="$config.icons.states.none"
                  v-if="item.direction === 'to_device' && item.is_sent"
                ></i>
                <i
                  :class="$config.icons.states.success"
                  v-if="item.direction === 'to_device' && item.is_sent"
                ></i>
                {{ dt.timestampToLocalDateTime(item.timestamp) }} - {{ item.text.substring(0, 200) }}
                <i :class="$config.icons.general.file" class="row-icon-button"
                   v-if="item.command && Object.keys(item.command).length" @click="showCommandData(item)"
                ></i>
                <i :class="$config.icons.general.file" class="row-icon-button"
                   v-if="item.data && Object.keys(item.data).length" @click="showCommandData(item)"
                ></i>
              </div>
              <div class="command-item-response" v-if="item.response">
                <i :class="$config.icons.general.info"></i>
                {{ item.response }}
              </div>
            </div>
            <ScrollTrigger @scroll_threshold="onScrollCommands" :threshold="100"></ScrollTrigger>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!--   Command Queue Modal Template-->
    <b-modal
      id="modal-command-queue"
      centered
      class="modal-content"
      size="xl"
      title="Command Queue"
    >
      <admin-device-command-queue :device_imei="deviceInternal.device_imei"></admin-device-command-queue>
    </b-modal>
    <!--   Command Help Modal Template-->
    <b-modal
      id="modal-commands-help"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Command Help"
    >
      <device-command-help modal-id="modal-commands-help" @change="selectCommand"></device-command-help>
    </b-modal>
    <!--  Command Data View -->
    <b-modal
      id="modal-view-command"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="View Command Details"
    >
      <div class="flex-column" v-if="selectedCommand">
        <div class="flex-row">
          <div>
            <label>Id: </label>
            {{ selectedCommand.id }}
          </div>
          <div>
            <label>Direction: </label>
            {{ selectedCommand.direction }}
          </div>
          <div>
            <label>Timestamp: </label>
            {{ dt.timestampToLocalDateTime(selectedCommand.timestamp) }}
          </div>
          <div>
            <label>Command Text: </label>
            {{ selectedCommand.text }}
          </div>
          <div>
            <label>Command Method: </label>
            {{ selectedCommand.method }}
          </div>
        </div>

        <label>Command Data:</label>
        <textarea
          v-model="selectedCommandJson"
          type="text"
          rows="12"
          id="rawJSON"
          placeholder="Command JSON"
          class="w-100"
          disabled
        ></textarea>

        <b-button @click="$bvModal.hide('modal-view-command')">Close</b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import DeviceCommandHelp from './device_command_types/DeviceCommandHelp'
import moment from 'moment'
import AdminCommand213LU from '@/components/admin/device_command_types/AdminCommand213LU'
import AdminCommandGV20 from '@/components/admin/device_command_types/AdminCommandGV20'
import AdminCommandNT06E from '@/components/admin/device_command_types/AdminCommandNT06E'
import AdminDeviceCommandQueue from '@/components/admin/AdminDeviceCommandQueue'
import AdminCommandVL502 from '@/components/admin/device_command_types/AdminCommandVL502.vue'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'
import ScrollTrigger from '@/components/shared/ScrollTrigger.vue'

export default {
  name: 'admin-command-device',
  components: {ScrollTrigger, AdminDeviceCommandQueue, DeviceCommandHelp},
  props: {
    device: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      currentCommand: '',
      filter: '',
      dt: dt,
      deviceHistory: [],
      deviceInternal: {
        device_id: 1,
        device_password: 'password',
        device_phone: '042331344',
        alarm_status: false,
        alarm_changed_time_utc: 32142141414,
        overspeed_limit: 0,
        vibration_alarm_active: false,
        odometer_reading: 0,
        has_subscription: true,
        icon: 'Car',
        device_imei: '351608083567144',
        name: '<< Loading device >>',
        plant_num: 'matty D',
        registration: '',
        subscription_expiry: '2017-06-01',
        user_id: 1,
        livetrack: false,
        geolimit: false,
        voltage: 12.0,
        hours_tracked: '200'
      },
      // JSON Data for Command Help/Select Modal
      commandFields: [
        {
          key: 'button',
          label: '-',
          sortable: false,
          thStyle: {
            width: '80px'
          }

        },
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'command',
          label: 'Command',
          sortable: true
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true
        }
      ],
      refreshTimer: null,
      deviceControlComponent: null,
      controlComponents: {
        'device_benway_gv20': AdminCommandGV20,
        'device_sinocastel_213lu': AdminCommand213LU,
        'device_kingwo_nt06e': AdminCommandNT06E,
        'device_concox_vl502': AdminCommandVL502,
        'device_concox_vl512': AdminCommandGV20,
      },
      selectedCommandJson: null,
      selectedCommand: null,
      autoScroll: false,
      pageSize: 50,
      page: 0,
    }
  },
  async created() {
    if (this.device) {
      this.updateCommandHistory()
      this.deviceInternal = this.device
      this.startTimer()
    }
  },
  async mounted() {
    if (this.deviceInternal.device_type in this.controlComponents) {
      this.deviceControlComponent = this.controlComponents[this.deviceInternal.device_type]
    } else {
      ErrorHelper.displayGeneralErrorToast('Unknown Device Type. No command control found.')
      console.log(`Unknown Device Type ${this.deviceInternal.device_type}.`)
      console.log('Known Types: ', this.controlComponents)
    }
  },
  methods: {
    async updateCommandHistory(append = false, ignoreScroll = false) {
      this.loading = true
      let commandHistory = await DataProvider.getDeviceCommands(this.device.device_imei, this.page * this.pageSize, this.pageSize)
      if (commandHistory.success) {
        console.log('Command History: ', commandHistory.data)
        let oldHistory = this.deviceHistory
        if (!append) {
          this.deviceHistory = commandHistory.data
        } else {
          this.deviceHistory = this.deviceHistory.concat(commandHistory.data)
        }
        // let oldHistory = this.deviceHistory
        // this.deviceHistory = commandHistory.data.sort((a, b) => b.timestamp - a.timestamp)

        // Move scrollbar to bottom
        if (!ignoreScroll && this.autoScroll && this.deviceHistory.length !== oldHistory.length) {
          this.scrollCommandBox()
        }
      } else {
        ErrorHelper.displayDataErrorToast(commandHistory)
      }
      this.loading = false
    },
    sendCommand: async function (sendingMethod) {
      let commandSend = await DataProvider.sendDeviceCommands(this.device.device_imei, this.currentCommand, sendingMethod)
      if (commandSend.success) {
        console.log('Command Response: ', commandSend)
        this.$bvToast.toast('Command sent to device!',
          {
            title: 'Command Registered',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        this.updateCommandHistory()
      } else {
        ErrorHelper.displayDataErrorToast(commandSend)
      }
    },
    selectCommand: function (command) {
      console.log(command)
      this.currentCommand = command.command
      this.$bvModal.hide('modal-commands-help')
    },
    // Monitor keys looking for ENTER to be pressed.
    keydownHandler: function (event) {
      if (event.which === 13) {
        this.sendCommand('data')
      }
    },
    scrollCommandBox: function () {
      this.$refs.commandHistory.scrollTop = 0
    },
    onScrollCommands() {
      this.stopTimer()
      if (!this.loading) {
        this.loading = true
        this.page += 1
        this.updateCommandHistory(true, false)
      }
      console.log('Scroll Triggered!')
    },
    toggleTimer() {
      console.log(this.refreshTimer)
      if (this.refreshTimer) {
        this.stopTimer()
      } else {
        this.startTimer()
      }
    },
    startTimer: function () {
      this.refreshTimer = setInterval(() => {
        this.updateCommandHistory()
      }, 5000)
    },
    stopTimer: function () {
      clearTimeout(this.refreshTimer)
      this.refreshTimer = null
    },
    formatTimestamp: function (timestamp) {
      return moment.unix(timestamp).local().format('YYYY-MM-DD HH:mm')
    },
    showCommandData: function (item) {
      console.log('Selected Item: ', item)
      this.selectedCommand = item
      if (this.selectedCommand.type === 'DeviceReply') {
        this.selectedCommandJson = JSON.stringify(item.data, null, 2)
      } else {
        this.selectedCommandJson = JSON.stringify(item.command, null, 2)
      }

      this.$bvModal.show('modal-view-command')
    }
  },
  beforeDestroy() {
    this.stopTimer()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../variables";

.admin-command-device {
  width: 100%;
  height: 100%;
  color: $text-color-invert;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.command-history-title {
  padding: 5px;
  background: $theme-color-background-4;
  font-size: 1.2em;
  font-weight: bold;
  justify-content: space-between;
  align-content: center;
}

.command-history {
  background: $default-content-background;
  height: calc(100% - 56px);
  overflow-y: scroll;
  max-height: 60vh;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.command-list-container {
  height: 75vh;
  overflow-y: scroll;
}

.command-item {
  text-align: left;
}

.command-item-response {
  text-align: left;
  color: $theme-color-primary-3;
  margin-left: 1rem;
}

.from_device_indicator {
  color: orange;
}

.to_device_indicator {
  color: rgb(102, 199, 72);
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.selected {
  color: $theme-color-primary-3;
}
</style>
