<template>
  <div class="device-command-help">
      <b-card v-if="!loading">
        <b-card-body>
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
              debounce="500"
              class="text-input"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
          <div class="command-list-container">
            <b-table striped hover :items="commandList" :fields="commandFields" fixed
                     :filter="filter" >
              <!--Select Row Element-->
              <template v-slot:cell(button)="row">
                <b-button @click="selectCommand(row.item)">USE</b-button>
              </template>
            </b-table>
          </div>
        </b-card-body>
      </b-card>
  </div>
</template>

<script>

export default {
  name: 'device-command-help',
  components: { },
  props: {
    modalId: String,
    commandList: Array
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      currentCommand: '',
      filter: '',
      // JSON Data for Command Help/Select Modal
      commandFields: [
        { key: 'button',
          label: '-',
          sortable: false,
          thStyle: {
            width: '80px'
          }

        },
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'command',
          label: 'Command',
          sortable: true
        },
        { key: 'description',
          label: 'Description',
          sortable: true
        }
      ]
    }
  },
  mounted () {
    console.log(this.commandList)
    if (this.commandList) {
      this.loading = false
    }
  },
  methods: {
    selectCommand: function (command) {
      this.$emit('change', command)
      this.$bvModal.hide(this.modalId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

  .admin-command-device {
    position: absolute;
    width: 100%;
    height: 100%;
    color: $text-color-invert;
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  .command-history {
    background: $default-content-background;
    height: calc(100% - 56px);
    overflow-y: scroll;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .command-list-container {
    height: 75vh;
    overflow-y: scroll;
  }

  .command-item {
    text-align: left;
  }

  .command-item-response {
    text-align: left;
    color: $theme-color-primary-3;
    margin-left: 1rem;
  }

</style>
